import React, { Component, Fragment, } from 'react';
import {
  number, shape, string, object, arrayOf, bool, func,
} from 'prop-types';
import { Query, Mutation, } from 'react-apollo';
import { MUTATION_DO_STAND_CALL, MUTATION_UNDO_STAND_CALL, } from '../../gql/mutations';

import { QUERY_ENUM_DROPDOWN, } from '../../gql/queries';
import { CALL_STATES, } from './DynamicTableSettings';
import {
  parseDateToHuman,
  parseTimeToHuman,
  setDateTodayMinTime,
  setDateTodayMaxTime,
} from '../../../../logic/date';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { PARAMS_TYPES, VALUE_TYPES, } from '../../../../components/Table/FilteredTableLocal';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import Table from '../../../../atoms/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import Button from '../../../../atoms/Button/Button';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';

import Search from '../../../../styles/icons/Search';


const isThActive = (column, filter) => {
  if (Object.prototype.hasOwnProperty.call(filter.params, column.attribute)) {
    const {
      from, to, type, value,
    } = filter.params[column.attribute];

    if (type === PARAMS_TYPES.TEXT) {
      return (value !== '');
    }
    if (type === PARAMS_TYPES.SELECT) {
      return (!!value);
    }
    if (type === PARAMS_TYPES.DATE) {
      return (!!from || !!to);
    }
  }

  return false;
};


class DynamicTableView extends Component {
  componentDidMount() {
    const { subscribeVariables, subscribeToMore, } = this.props;

    if (subscribeVariables) {
      subscribeToMore(subscribeVariables);
    }
  }


  handleClickAction = (e, data) => {
    const { onAction, } = this.props;

    e.stopPropagation();

    if (onAction) onAction(data);
  }


  renderHeader = () => {
    const {
      translationsCols,
      tableConfig,
      onChangeSort,
      filter,
      translations,
      onChangeParam,
      languageId,
    } = this.props;
    let showSecondFilterRow = false;

    return (
      <thead>
        <tr>
          {tableConfig.map((item) => {
            if (item.sortable.enable) {
              return (
                <THSortable
                  key={item.attribute}
                  title={translationsCols[item.attribute]}
                  name={item.attribute}
                  isActiveFilter={isThActive(item, filter)}
                  filter={filter}
                  style={{ minWidth: '10rem', width: '10rem', }}
                  onSort={onChangeSort}
                />
              );
            }
            return (
              <th
                className="table--header-title"
                style={{ minWidth: '10rem', width: '10rem', }}
              >
                {translationsCols[item.attribute]}
              </th>
            );
          })}
          <th
            className="table--header-title table--text-align-right"
            style={{ minWidth: '4rem', }}
          >
            {translations.stand.thActions}
          </th>

        </tr>

        <tr>
          {tableConfig.map((column) => {
            if (!column.filtrable.enable) return <th key={column.attribute} />;
            if (column.filtrable.type === PARAMS_TYPES.TEXT) {
              return (
                <th key={column.attribute}>
                  <Input
                    type="text"
                    placeholder={translations.common.filter}
                    autoComplete="off"
                    value={filter.params[column.attribute].value}
                    active={filter.params[column.attribute].value !== ''}
                    onChange={(e) => onChangeParam(column.attribute, 'value', e.target.value)}
                    onClear={() => onChangeParam(column.attribute, 'value', '')}
                    size="sm"
                  />
                </th>
              );
            }
            if (column.filtrable.type === PARAMS_TYPES.SELECT) {
              return (
                <th key={column.attribute}>
                  <Query
                    query={QUERY_ENUM_DROPDOWN}
                    variables={{
                      languageId,
                      type: column.filtrable.query,
                    }}
                    fetchPolicy="no-cache"
                  >
                    {({ loading, error, data, }) => (
                      <InputSelect
                        value={filter.params[column.attribute].value}
                        active={!!filter.params[column.attribute].value}
                        options={(data && data.fetchDropDown)
                          ? data.fetchDropDown.data
                          : []
                        }
                        onChange={(option) => onChangeParam(column.attribute, 'value', option, false)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isClearable
                        isLoading={loading}
                        error={error}
                        placeholder={translations.common.filter}
                        size="sm"
                      />
                    )}
                  </Query>
                </th>
              );
            }
            if (column.filtrable.type === PARAMS_TYPES.DATE) {
              showSecondFilterRow = true;
              return (
                <th key={column.attribute}>
                  <InputDateSelect
                    placeholder={translations.common.from}
                    size="sm"
                    value={filter.params[column.attribute].from}
                    active={!!filter.params[column.attribute].from}
                    clearable
                    onChange={(newDate) => onChangeParam(column.attribute, 'from', newDate ? setDateTodayMinTime(newDate) : null, false)}
                  />
                </th>
              );
            }
            return <th key={column.attribute} />;
          })}
          <th />
        </tr>

        {showSecondFilterRow && (
          <tr>
            {tableConfig.map((column) => {
              if (column.filtrable.enable && column.filtrable.type === PARAMS_TYPES.DATE) {
                showSecondFilterRow = true;
                return (
                  <th key={column.attribute}>
                    <InputDateSelect
                      placeholder={translations.common.to}
                      size="sm"
                      value={filter.params[column.attribute].to}
                      active={!!filter.params[column.attribute].to}
                      clearable
                      onChange={(newDate) => onChangeParam(column.attribute, 'to', newDate ? setDateTodayMaxTime(newDate) : null, false)}
                    />
                  </th>
                );
              }
              return <th key={column.attribute} />;
            })}
            <th />
          </tr>
        )}
      </thead>
    );
  }


  renderValue = (col, row) => {
    // date
    if (col.type === VALUE_TYPES.DATE) return parseDateToHuman(row[col.attribute], false);
    // dateTime
    if (col.type === VALUE_TYPES.DATETIME) return parseTimeToHuman(row[col.attribute]);
    // default
    return row[col.attribute];
  }


  handleMutation = (mutate, variables) => (
    mutate({
      variables,
    })
  )


  renderDoCallAction = (id, standId, actionName) => {
    const { translations, addNotification, } = this.props;

    return (
      <Mutation
        mutation={MUTATION_DO_STAND_CALL}
        onCompleted={() => addNotification({
          status: 'success',
          title: translations.stand.messageTitleCalled,
        })}
        onError={() => addNotification({
          status: 'error',
          title: translations.errors.SOMETHING_HAPPENED,
        })}
      >
        {(mutate, { loading, }) => (
          <Button
            style={{ minWidth: '7rem', }}
            size="sm"
            type="button"
            color="tertiary"
            isLoading={loading}
            onClick={() => this.handleMutation(mutate, { standId, transportationId: id, })}
          >
            {actionName}
          </Button>
        )}
      </Mutation>
    );
  }

  renderUndoCallAction = (id, standId, actionName) => {
    const { translations, addNotification, } = this.props;

    return (
      <Mutation
        mutation={MUTATION_UNDO_STAND_CALL}
        onCompleted={() => addNotification({
          status: 'success',
          title: translations.stand.messageTitleCallCanceled,
        })}
        onError={() => addNotification({
          status: 'error',
          title: translations.errors.SOMETHING_HAPPENED,
        })}
      >
        {(mutate, { loading, }) => (
          <Button
            style={{ minWidth: '7rem', }}
            size="sm"
            type="button"
            color="error"
            isLoading={loading}
            onClick={() => this.onToggleCall({ standId, transportationId: id, })}
          >
            {actionName}
          </Button>
        )}
      </Mutation>
    );
  }


  renderCallActions = (row) => {
    const {
      id, callState,
    } = row;
    const { standId, translations, stand, } = this.props;

    switch (callState) {
      case CALL_STATES.WAITING:
        return this.renderDoCallAction(id, standId, translations.stand.doCall);
      case CALL_STATES.CALLED:
        return (
          <>
            {this.renderUndoCallAction(id, standId, translations.stand.undoCall)}
            {this.renderDoCallAction(id, standId, translations.stand.repeatCall)}
          </>
        );
      default:
        return <><span /></>;
    }
  };

  renderRows = () => {
    const {
      tableConfig,
      rows,
      loading,
      error,
      main,
      translations,
      actionNameActive,
      actionNameInactive,
      onDetail,
    } = this.props;

    const colsCount = tableConfig.length + 1;

    if (loading) {
      return (
        <TableLoading
          colsCount={colsCount}
          rowsCount={rows.length}
        />
      );
    }
    if (error || !rows) {
      return (
        <TableError
          colsCount={colsCount}
          error={error}
        />
      );
    }

    if (rows.length < 1) {
      return (
        <TableNoData
          colsCount={colsCount}
          text={translations.stand.standTableNoData}
        />
      );
    }

    return (
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>

            {tableConfig.map((col) => (
              <td key={col.attribute}>
                {this.renderValue(col, row)}
              </td>
            ))}

            <td className="table--text-align-right table--noPadding">
              <ButtonGrp>
                {(
                  this.renderCallActions(row)
                )}
                {onDetail && (
                  <Tooltip text={translations.common.detail}>
                    {(events) => (
                      <ButtonIcon
                        size="sm"
                        color="tertiary"
                        onClick={() => onDetail(row)}
                        {...events}
                      >
                        <Search />
                      </ButtonIcon>
                    )}
                  </Tooltip>
                )}
                {main && (
                  <Button
                    style={{ minWidth: '7rem', }}
                    size="sm"
                    color={row.active ? 'success' : 'warning'}
                    onClick={(e) => this.handleClickAction(e, row)}
                  >
                    {row.active ? actionNameInactive : actionNameActive}
                  </Button>
                )}
              </ButtonGrp>
            </td>

          </tr>
        ))}
      </tbody>
    );
  }


  render() {
    const {
      loading,
      rowsFilter,
      onChangeFilter,
    } = this.props;

    return (
      <Fragment>
        <Table fillContent>
          {this.renderHeader()}
          {this.renderRows()}
        </Table>
        <Pagination
          loading={loading}
          dataFilter={rowsFilter}
          onChangePage={onChangeFilter}
        />
      </Fragment>
    );
  }
}


DynamicTableView.propTypes = {
  subscribeVariables: object.isRequired,
  main: bool.isRequired,
  callButton: bool.isRequired,
  tableConfig: arrayOf(shape({
    attribute: string.isRequired,
    type: string.isRequired,
    sortable: shape({
      enable: bool.isRequired,
    }).isRequired,
    filtrable: shape({
      enable: bool.isRequired,
      query: string,
      type: string,
    }).isRequired,
  })).isRequired,
  translations: object.isRequired,
  translationsCols: object.isRequired,
  actionNameActive: string.isRequired,
  actionNameInactive: string.isRequired,
  languageId: string.isRequired,
  standId: string.isRequired,
  stand: object.isRequired,
  rows: arrayOf(object).isRequired,
  loading: bool.isRequired,
  error: object,
  filter: shape({
    offset: number.isRequired,
    limit: number.isRequired,
    sortBy: string.isRequired,
    order: string.isRequired,
    params: object,
  }).isRequired,
  rowsFilter: shape({
    offset: number.isRequired,
    limit: number.isRequired,
    sortBy: string.isRequired,
    order: string.isRequired,
    count: number.isRequired,
    params: object,
  }).isRequired,
  // methods
  subscribeToMore: func.isRequired,
  onChangeFilter: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
  onDetail: func,
  onAction: func,
  addNotification: func.isRequired,
};

DynamicTableView.defaultProps = {
  error: undefined,
  onDetail: undefined,
  onAction: undefined,
};


export default withNotifications(DynamicTableView);
