import React, { Component, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { initRecordForm, } from '../forms/recordStructure';
import RecordViewView from './RecordViewView';
import { gateTypeToTranslationKey, } from '../enums/gateTypeEnum';


class RecordViewLogic extends Component {
  constructor(props) {
    super(props);

    const { data, translations, } = this.props;

    this.state = {
      detailForm: {
        ...initRecordForm,
        values: {
          ...initRecordForm.values,
          ...data,
          time: data.time ? new Date(data.time) : null,
          gateType: data.gateType ? {
            id: data.gateType,
            name: translations.form[gateTypeToTranslationKey(data.gateType)],
          } : null,
          gateVehicleGroupId: data.gateVehicleGroupId ? {
            id: data.gateVehicleGroupId,
            name: data.gateVehicleGroupName,
          } : null,
          orders: data.orders,
        },
      },
    };
  }

  render() {
    const { detailForm, } = this.state;
    const {
      translations, onToggle,
    } = this.props;

    return (
      <RecordViewView
        // data
        detailForm={detailForm}
        translations={translations}
        // methods
        onToggle={onToggle}
      />
    );
  }
}


RecordViewLogic.propTypes = {
  // data
  data: shape({
    // basic
    id: string,
    driverName: string,
    driverPhoneNumber: string,
    gateVehicleGroupId: string,
    gateVehicleGroupName: string,
    registrationNumber: string,
    gateType: string,
    gateTypeName: string,
    time: string,
    // extra
    rnPicture: string,
    note: string,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  // methods
  onToggle: func.isRequired,
};


export default withNotifications(RecordViewLogic);
