import React from 'react';
import { string, object, } from 'prop-types';
import styled from 'styled-components';

import { TRANSLATIONS_STOREKEEPER, } from '../gql/translations';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import Platforms from '../components/Platforms/Platforms';
import Queue from '../components/Queue/Queue';
import media from '../../../styles/media';


const StyleStorekeeperPage = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.MD`
    flex-wrap: nowrap;
  `}
`;


const StorekeeperPage = ({ translations, resources, ...rest }) => (
  <PageFullScreen>

    <Breadcrumb>
      <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
      <BreadcrumbItem>{translations.common.storekeeper}</BreadcrumbItem>
    </Breadcrumb>

    <StyleStorekeeperPage>
      {resources.storekeeper_platform_manual_whole_queue_management && (
        <Queue
          translations={translations}
          {...rest}
        />
      )}
      <Platforms
        translations={translations}
        resources={resources}
        {...rest}
      />
    </StyleStorekeeperPage>

  </PageFullScreen>
);


StorekeeperPage.propTypes = {
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
};


export default withTranslations(TRANSLATIONS_STOREKEEPER)(StorekeeperPage);
