import React, { Component, } from 'react';
import { string, object, func, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_SCHEDULE_TIMELINE_FILTER, QUERY_FILTER_TIMELINE_BY_TRANSPORTATION_TYPE, } from '../../../gql/queries';
import { getInitTimelineFilter, getFilterHoursOptions, } from '../utils';
import { parseTimelineByTransportationType, } from '../../../../../components/Timeline/parseDataV2';
import TabView from './TabView';


class TabTimelinePlatforms extends Component {
  constructor(props) {
    super(props);

    const { translations, } = this.props;

    const rangeOptions = getFilterHoursOptions(translations);

    this.state = {
      timeline: null,
      initFilter: getInitTimelineFilter({}, rangeOptions),
    };
  }


  handleTimelineCompleted = (response) => {
    try {
      const timeline = response.filterTimelineByTransportationType
        ? parseTimelineByTransportationType(response.filterTimelineByTransportationType)
        : null;

      this.setState({
        timeline,
      });
    } catch (err) {
      this.handleTimelineError();
    }
  }


  handleTimelineError = () => {
    this.setState({
      timeline: null,
    });
  }


  render() {
    const { timeline, initFilter, } = this.state;
    const {
      // data
      languageId,
      translations,
      resources,
      // methods
      onChangePage,
    } = this.props;

    return (
      <Query query={QUERY_SCHEDULE_TIMELINE_FILTER}>
        {({ data: { scheduleFilter, }, }) => (
          <Query
            query={QUERY_FILTER_TIMELINE_BY_TRANSPORTATION_TYPE}
            variables={{
              languageId,
              from: scheduleFilter && scheduleFilter.from ? scheduleFilter.from : initFilter.from,
              to: scheduleFilter && scheduleFilter.to ? scheduleFilter.to : initFilter.to,
              platformIds: undefined,
              transportationTypeId: scheduleFilter && scheduleFilter.transportationType
                ? scheduleFilter.transportationType.id
                : undefined,
            }}
            onCompleted={this.handleTimelineCompleted}
            onError={this.handleTimelineError}
            fetchPolicy="no-cache"
          >
            {(timelineData) => (
              <TabView
                // data
                timeline={timeline}
                timelineData={timelineData}
                initFilter={scheduleFilter || initFilter}
                languageId={languageId}
                translations={translations}
                resources={resources}
                // methods
                onChangePage={onChangePage}
              />
            )}
          </Query>
        )}
      </Query>
    );
  }
}


TabTimelinePlatforms.propTypes = {
  // data
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onChangePage: func.isRequired,
};


export default TabTimelinePlatforms;
