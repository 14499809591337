import React from 'react';
import { shape, object, array, } from 'prop-types';

import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import { parseDateToHuman, } from '../../../../logic/date';
import Input from '../../../../atoms/Input/Input';
import { getActionIconForRecord, } from '../utils';

const getDateValue = (values, action) => {
  const checkpoints = values.checkpoints ? values.checkpoints : [];
  const checkpoint = checkpoints.find((c) => c.action === action);
  return checkpoint && checkpoint.date ? parseDateToHuman(new Date(checkpoint.date)) : '';
};

const style = {
  position: 'absolute',
  right: 0,
  top: '1.7rem',
};

const RecordCheckpoints = ({ values, translations, }) => (
  <>
    <FormRowReadOnly label={translations.gatehouse.clearance} style={{ position: 'relative', }}>
      <Input
        type="text"
        value={getDateValue(values, 'clearance')}
        disabled
      />
      {getActionIconForRecord(values.checkpoints, 'clearance', style)}
    </FormRowReadOnly>
  </>
);


RecordCheckpoints.propTypes = {
  translations: object.isRequired,
  values: shape({
    checkpoints: array,
  }).isRequired,
};


export default RecordCheckpoints;
