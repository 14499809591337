import React from 'react';
import {
  func, object, shape, bool, string,
} from 'prop-types';
import { Query, Mutation, } from 'react-apollo';

import { MUTATION_CALL_TRANSPORTATION, } from '../../../gql/mutations';
import { QUERY_POSSIBLE_PLATFORMS, } from '../../../gql/queries';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import Button from '../../../../../atoms/Button/Button';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import FormBackground from '../../../../../components/Form/FormBackground';
import FormError from '../../../../../components/Form/FormError';
import InputSelect from '../../../../../atoms/InputSelect/InputSelect';
import FormRow from '../../../../../components/Form/FormRow';
import ValueText from '../../../../../atoms/Values/ValueText';
import FormRowReadOnly from '../../../../../components/Form/FormRowReadOnly';


const ModalCallView = ({
  // data
  showForm,
  callForm: {
    isValid,
    form,
    values,
  },
  transportation,
  languageId,
  translations,
  // methods
  onToggle,
  onChangeForm,
  onCallTransportation,
  onCallTransportationCompleted,
  onCallTransportationError,
}) => (
  <FormBackground>

    <FormRowReadOnly label={translations.form.no}>
      <ValueText>
        {transportation.no}
      </ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.truckRegistrationNumber}>
      <ValueText>
        {transportation.truckRegistration}
      </ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.transportationType}>
      <ValueText>
        {transportation.transportationTypeName}
      </ValueText>
    </FormRowReadOnly>

    {showForm ? (
      <FormRow
        label={translations.form.platform}
        input={form.platform}
      >
        <Query
          query={QUERY_POSSIBLE_PLATFORMS}
          fetchPolicy="no-cache"
          variables={{
            transportationId: transportation.id,
            languageId,
          }}
        >
          {({ loading, error, data, }) => (
            <InputSelect
              options={(data && data.fetchPossiblePlatforms)
                ? data.fetchPossiblePlatforms
                : []
              }
              value={values.platform}
              onChange={(newValue) => onChangeForm(form.platform.name, newValue)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              loading={loading}
              error={error}
              placeholder={translations.centralAppStorekeeper.placeholderModalCallPlatform}
              filterLabelOnly
            />
          )}
        </Query>
      </FormRow>
    ) : (
      <FormRowReadOnly label={translations.form.platform}>
        <ValueText>
          {values.platform.name}
        </ValueText>
      </FormRowReadOnly>
    )}

    <Mutation
      mutation={MUTATION_CALL_TRANSPORTATION}
      onCompleted={onCallTransportationCompleted}
      onError={onCallTransportationError}
    >
      {(mutationCall, { loading, error, }) => (
        <>
          <FormError
            isValid={isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                onClick={onToggle}
              >
                {translations.common.close}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                className="buttonGrp--right"
                color="success"
                disabled={!isValid}
                onClick={() => onCallTransportation(mutationCall)}
                isLoading={loading}
              >
                {translations.centralAppStorekeeper.btnQueueCall}
              </ButtonLoader>
            </Col>
          </Row>
        </>
      )}
    </Mutation>

  </FormBackground>
);


ModalCallView.propTypes = {
  // data
  showForm: bool.isRequired,
  callForm: shape({
    isValid: bool.isRequired,
    form: shape({
      platform: object.isRequired,
    }).isRequired,
    values: shape({
      platform: object,
    }).isRequired,
  }).isRequired,
  transportation: shape({
    id: string.isRequired,
    no: string.isRequired,
    truckRegistration: string.isRequired,
    transportationTypeName: string.isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onChangeForm: func.isRequired,
  onCallTransportation: func.isRequired,
  onCallTransportationCompleted: func.isRequired,
  onCallTransportationError: func.isRequired,
};


export default ModalCallView;
