import gql from 'graphql-tag';


/**
 * Notifications
 */
export const SUBSCRIPTION_NOTIFICATIONS_ADD = gql`
  subscription AddNotification($languageId: ID) {
    addNotification(languageId: $languageId) {
      id
      info
      notificationType
      type
      timestamp
      acknowledged
      acknowledgeTime
      transportationId
      transportation
      standId
      stand
    }
  }
`;

export const SUBSCRIPTION_NOTIFICATIONS_REMOVE = gql`
  subscription RemoveNotification {
    removeNotification
  }
`;


/**
 * Monitoring
 */
export const SUBSCRIPTION_LIVE_MONITORING_TRANSPORTATIONS_ADD = gql`
  subscription AddLiveTransportation ($state: String, $languageId: ID) {
    addLiveTransportation (state: $state, languageId: $languageId) {
      id
      no
      state
      stateUpdatedAt
      transportationTypeName
      truckRegistration
      trailerRegistration
      delayed
      date
      reservationTimeFrom
      reservationTimeTo
      reservationTypeId
      reservationTypeName
      platformId
      platformName
      clearanceState
    }
  }
`;

export const SUBSCRIPTION_LIVE_MONITORING_TRANSPORTATIONS_REMOVE = gql`
  subscription RemoveLiveTransportation($state: String) {
    removeLiveTransportation(state: $state)
  }
`;
