import React, { Component, Fragment, } from 'react';
import { string, object, } from 'prop-types';

import { QUERY_SIDEBAR_TRANSLATIONS, } from './gql/queries';
import { withTranslations, } from '../../logic/translations/withTranslations';
import SidebarLink from './SidebarLink';
import SideBarSubTitle from './SideBarSubTitle';
import SidebarCollapse from './SidebarCollapse';
import SidebarCollapseTitle from './SidebarCollapseTitle';

import StyledSidebar from './styles/StyledSidebar';
import Dashboard from '../../styles/icons/app/Dashboard';
import Monitoring from '../../styles/icons/app/Monitoring';
import LiveMonitor from '../../styles/icons/app/LiveMonitor';
import Notification from '../../styles/icons/app/Notification';
import Logistic from '../../styles/icons/app/Logistic';
import History from '../../styles/icons/app/History';
import Planning from '../../styles/icons/app/Planning';
import Folder from '../../styles/icons/app/Folder';
import Drivers from '../../styles/icons/app/Drivers';
import Enterprise from '../../styles/icons/app/Enterprise';
import Truck from '../../styles/icons/app/Truck';
import Settings from '../../styles/icons/app/Settings';
import Users from '../../styles/icons/app/Users';
import Stand from '../../styles/icons/app/Stand';
import Storekeeper from '../../styles/icons/app/Storekeeper';
import NotificationBadge from './NotificationBadge';
import Message from '../../styles/icons/app/Message';
import SidebarLicenseInfo from '../License/SidebarLicenseInfo';
import Parking from '../../styles/icons/app/Parking';
import InsideParking from '../../styles/icons/app/InsideParking';
import UserSettings from '../../styles/icons/app/UserSettings';
import Computer from '../../styles/icons/app/Computer';
import OneWay from '../../styles/icons/app/OneWay';
import Gatehouse from '../../styles/icons/app/Gatehouse';


class Sidebar extends Component {
  state = {
    monitoring: false,
    transportation: false,
    settings: false,
    directory: false,
    parking: false,
    rs: false,
  }


  handleToggleCollapse = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  }


  render() {
    const {
      transportation,
      directory,
      settings,
      monitoring,
      parking,
      rs,
    } = this.state;
    const {
      resources,
      languageId,
      translations,
    } = this.props;

    return (
      <StyledSidebar className="noSelect">
        {(translations) && (
          <Fragment>

            <SideBarSubTitle
              title={translations.common.menu}
            />

            <ul>
              <li>
                <SidebarLink exact to="/">
                  <span className="sidebar--item-icon"><Dashboard /></span>
                  <span>{translations.common.dashboard}</span>
                </SidebarLink>
              </li>
              {(resources.rs_user || resources.rs_blocking_day) && (
                <li>
                  <SidebarCollapseTitle
                    isOpen={rs}
                    onToggleCollapse={this.handleToggleCollapse}
                    toggleName="rs"
                  >
                    <span className="sidebar--item-icon"><Computer /></span>
                    <span>{translations.common.rs}</span>
                  </SidebarCollapseTitle>

                  <SidebarCollapse
                    isOpen={rs}
                  >
                    <ul>
                      {resources.rs_user && (
                        <li>
                          <SidebarLink
                            to="/reservation-system/users"
                            level={1}
                          >
                            <span className="sidebar--item-icon"><UserSettings /></span>
                            <span>{translations.common.rsUsers}</span>
                          </SidebarLink>
                        </li>
                      )}
                      {resources.rs_blocking_day && (
                        <li>
                          <SidebarLink
                            to="/reservation-system/blocking-days"
                            level={1}
                          >
                            <span className="sidebar--item-icon"><OneWay /></span>
                            <span>{translations.common.rsPlatformBlocks}</span>
                          </SidebarLink>
                        </li>
                      )}
                    </ul>
                  </SidebarCollapse>
                </li>
              )}
              {resources.monitoring && (
                <li>
                  <SidebarCollapseTitle
                    isOpen={monitoring}
                    onToggleCollapse={this.handleToggleCollapse}
                    toggleName="monitoring"
                  >
                    <span className="sidebar--item-icon"><Monitoring /></span>
                    <span>{translations.common.monitoringModule}</span>
                  </SidebarCollapseTitle>

                  <SidebarCollapse
                    isOpen={monitoring}
                  >
                    <ul>
                      <li>
                        <SidebarLink
                          to="/monitoring"
                          level={1}
                        >
                          <span className="sidebar--item-icon"><LiveMonitor /></span>
                          <span>{translations.common.monitoring}</span>
                        </SidebarLink>
                      </li>
                      <li>
                        <SidebarLink
                          to="/monitoring/notifications"
                          level={1}
                        >
                          <span className="sidebar--item-icon"><Notification /></span>
                          <span>{translations.common.notifications}</span>
                          <NotificationBadge languageId={languageId} />
                        </SidebarLink>
                      </li>
                    </ul>
                  </SidebarCollapse>
                </li>
              )}
              {resources.logistic && (
                <li>
                  <SidebarCollapseTitle
                    isOpen={transportation}
                    onToggleCollapse={this.handleToggleCollapse}
                    toggleName="transportation"
                  >
                    <span className="sidebar--item-icon"><Logistic /></span>
                    <span>{translations.common.transportation}</span>
                  </SidebarCollapseTitle>

                  <SidebarCollapse
                    isOpen={transportation}
                  >
                    <ul>
                      {resources.logistic_plan_read && (
                        <li>
                          <SidebarLink
                            to="/transportation/schedule"
                            level={1}
                          >
                            <span className="sidebar--item-icon"><Planning /></span>
                            <span>{translations.common.schedule}</span>
                          </SidebarLink>
                        </li>
                      )}
                      {resources.logistic_history && (
                        <li>
                          <SidebarLink
                            to="/transportation/history"
                            level={1}
                          >
                            <span className="sidebar--item-icon"><History /></span>
                            <span>{translations.common.history}</span>
                          </SidebarLink>
                        </li>
                      )}
                    </ul>
                  </SidebarCollapse>
                </li>
              )}

              {resources.directory && (
                <li>
                  <SidebarCollapseTitle
                    isOpen={directory}
                    onToggleCollapse={this.handleToggleCollapse}
                    toggleName="directory"
                  >
                    <span className="sidebar--item-icon"><Folder /></span>
                    <span>{translations.common.directory}</span>
                  </SidebarCollapseTitle>

                  <SidebarCollapse
                    isOpen={directory}
                  >
                    <ul>
                      {resources.directory_company_read && (
                        <li>
                          <SidebarLink
                            to="/directory/companies"
                            level={1}
                          >
                            <span className="sidebar--item-icon"><Enterprise /></span>
                            <span>{translations.common.companies}</span>
                          </SidebarLink>
                        </li>
                      )}
                      {resources.directory_driver_read && (
                        <li>
                          <SidebarLink
                            to="/directory/drivers"
                            level={1}
                          >
                            <span className="sidebar--item-icon"><Drivers /></span>
                            <span>{translations.common.drivers}</span>
                          </SidebarLink>
                        </li>
                      )}
                      {resources.directory_truck_read && (
                        <Fragment>
                          <li>
                            <SidebarLink
                              to="/directory/trucks"
                              level={1}
                            >
                              <span className="sidebar--item-icon"><Truck /></span>
                              <span>{translations.common.trucks}</span>
                            </SidebarLink>
                          </li>
                          <li>
                            <SidebarLink
                              to="/directory/vehicle-groups"
                              level={1}
                            >
                              <span className="sidebar--item-icon"><Truck /></span>
                              <span>{translations.common.vehicleGroups}</span>
                            </SidebarLink>
                          </li>
                        </Fragment>
                      )}
                    </ul>
                  </SidebarCollapse>
                </li>
              )}

              {resources.storekeeper_central_app && (
                <li>
                  <SidebarLink to="/storekeeper">
                    <span className="sidebar--item-icon"><Storekeeper /></span>
                    <span>{translations.common.storekeeper}</span>
                  </SidebarLink>
                </li>
              )}

              {resources.stand && (
                <li>
                  <SidebarLink to="/stands">
                    <span className="sidebar--item-icon"><Stand /></span>
                    <span>{translations.common.stands}</span>
                  </SidebarLink>
                </li>
              )}

              {resources.parking_read && (
                <li>
                  <SidebarCollapseTitle
                    isOpen={parking}
                    onToggleCollapse={this.handleToggleCollapse}
                    toggleName="parking"
                  >
                    <span className="sidebar--item-icon"><Parking /></span>
                    <span>{translations.common.parking}</span>
                  </SidebarCollapseTitle>
                  <SidebarCollapse
                    isOpen={parking}
                  >
                    <ul>
                      <li>
                        <SidebarLink
                          to="/parking"
                          level={1}
                        >
                          <span className="sidebar--item-icon"><Parking /></span>
                          <span>{translations.common.mainParking}</span>
                        </SidebarLink>
                      </li>
                      <li>
                        <SidebarLink
                          to="/parking/internal"
                          level={1}
                        >
                          <span className="sidebar--item-icon"><InsideParking /></span>
                          <span>{translations.common.internalParking}</span>
                        </SidebarLink>
                      </li>
                    </ul>
                  </SidebarCollapse>
                </li>
              )}

              {resources.gatehouse_module && (
                <li>
                  <SidebarLink to="/gatehouse">
                    <span className="sidebar--item-icon"><Gatehouse /></span>
                    <span>Vrátnice</span>
                  </SidebarLink>
                </li>
              )}

              {resources.settings && (
                <li>
                  <SidebarCollapseTitle
                    isOpen={settings}
                    onToggleCollapse={this.handleToggleCollapse}
                    toggleName="settings"
                  >
                    <span className="sidebar--item-icon"><Settings /></span>
                    <span>{translations.common.settings}</span>
                  </SidebarCollapseTitle>

                  <SidebarCollapse
                    isOpen={settings}
                  >
                    <ul>
                      {resources.settings && (
                        <li>
                          <SidebarLink
                            to="/settings/users"
                            level={1}
                          >
                            <span className="sidebar--item-icon"><Users /></span>
                            <span>{translations.common.userSettings}</span>
                          </SidebarLink>
                        </li>
                      )}
                      {resources.settings && (
                        <li>
                          <SidebarLink
                            to="/settings/sms"
                            level={1}
                          >
                            <span className="sidebar--item-icon"><Message /></span>
                            <span>{translations.common.smsAdministration}</span>
                          </SidebarLink>
                        </li>
                      )}
                    </ul>
                  </SidebarCollapse>
                </li>
              )}
            </ul>

          </Fragment>
        )}

        <div className="sidebar--footer">
          <SidebarLicenseInfo />
        </div>
      </StyledSidebar>
    );
  }
}


Sidebar.propTypes = {
  resources: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
};


export default withTranslations(QUERY_SIDEBAR_TRANSLATIONS)(Sidebar);
