import React, { Component, } from 'react';
import {
  func, shape, bool, object,
} from 'prop-types';

import { QUERY_COMPANY_DETAIL_TRANSLATIONS, } from '../../gql/translations';
import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initCompanyForm, } from '../forms/structure';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import CompanyCreateView from './CompanyCreateView';


class CompanyCreate extends Component {
  constructor(props) {
    super(props);

    const { presetValues, } = props;

    this.state = presetValues
      ? {
        ...initCompanyForm,
        detailForm: {
          ...initCompanyForm.detailForm,
          values: {
            ...initCompanyForm.detailForm.values,
            ...presetValues,
          },
        },
      }
      : {
        ...initCompanyForm,
      };
  }


  /**
   * Form - onChange
   */
  handleValueChange = (name, value) => {
    const { detailForm, } = this.state;
    const newState = changeAndValidateInput(detailForm, name, value);

    this.setState({
      detailForm: newState,
    });
  }


  /**
   * onCreate
   */
  handleCreate = (mutate) => {
    const { detailForm, detailForm: { values, }, } = this.state;

    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const variables = {
        name: values.name,
        contactPerson: values.contactPerson,
        email: values.email,
        phoneNumber: values.phoneNumber,
        identificationNumber: values.identificationNumber,
        vatNumber: values.vatNumber,
        street: values.street,
        city: values.city,
        zip: values.zip,
        state: values.state,
        info: values.info,
      };
      mutate({
        variables,
      });
    }
  }


  /**
   * onCreateCompleted
   */
  handleCreateComplete = (responseData) => {
    const {
      translations, onToggle, onCreated, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.created,
    });

    if (onCreated) onCreated(responseData);
  }


  /**
   * onCreateError
   */
  handleCreateError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(detailForm, extensions.exception.data);

              this.setState({
                detailForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  render() {
    const { detailForm, } = this.state;
    const { translations, modalData: { isOpen, }, onToggle, } = this.props;

    if (!isOpen) return null;

    return (
      <Modal
        title={translations.directory.createModalCompanyTitle}
        isOpen
        onClose={onToggle}
        size="MD"
        disablePadding
      >
        <CompanyCreateView
          detailForm={detailForm}
          translations={translations}
          onChange={this.handleValueChange}
          onToggle={onToggle}
          onCreate={this.handleCreate}
          onCreateComplete={this.handleCreateComplete}
          onCreateError={this.handleCreateError}
        />
      </Modal>
    );
  }
}


CompanyCreate.propTypes = {
  // data
  modalData: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  presetValues: object,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onCreated: func,
  addNotification: func.isRequired,
};

CompanyCreate.defaultProps = {
  presetValues: undefined,
  onCreated: undefined,
};


export default pipe(
  withNotifications,
  withTranslations(QUERY_COMPANY_DETAIL_TRANSLATIONS),
)(CompanyCreate);
