import { Component, } from 'react';
import { func, } from 'prop-types';

import { client, } from '../../logic/graphql/apollo';
import { HANDLE_SCANNED_ID_MUTATION, } from './gql/mutations';
import { withNotifications, } from '../../logic/notifications/withNotifications';


// const uuidRegexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
// const uuidRegexExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/gi;

const TRANSLATIONS = {
  qrCodeSuccessRead: 'Úspěšně načtené ID z QR kódu, zpracovává se',
  processingError: 'Chyba při zpracovaní přepravy',
  invalidQrCode: 'Načtený QR kód je neplatný, prosím zkuste ho načíst znovu',
  resultNotFound: 'Načtená přeprava nebyla nalezena',
  resultNotCalled: 'Přeprava ještě není vyvolána',
  resultAlreadyFinished: 'Přeprava je již ukončena',
  resultClearing: 'Přeprava se začala odbavovat',
  resultCleared: 'Odbavování přepravy se ukončilo',
  resultInvalidState: 'Přeprava je v přerusena, nebo v jiném nepodporovaném stavu',
  resultUnauthorized: 'Na tuto přepravu nemáte opravnění',
};

const MARGIN_CHAR_CODE = 'Semicolon';
const MARGIN_SIZE = 3;

const getChar = (event) => {
  if (event.code.startsWith('Digit')) {
    return event.code[5];
  }
  if (event.code === 'Minus') {
    return '-';
  }
  if (event.code === 'Slash') {
    return '-';
  }
  return event.key;
};

class ScannerInput extends Component {
  constructor(props) {
    super(props);

    this.marginCharsRead = 0;
    this.status = 'WAITING'; // WAITING | READING_UUID
    this.uuid = '';
  }

  componentDidMount() {
    document.addEventListener('keypress', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.code === MARGIN_CHAR_CODE) {
      if (this.status === 'WAITING') {
        this.marginCharsRead++;

        // reading the last margin char
        if (this.marginCharsRead === MARGIN_SIZE) {
          this.marginCharsRead = 0;
          this.status = 'READING_UUID';
          this.uuid = '';
        }
      } else /* if (state === 'READING_UUID') */ {
        this.marginCharsRead++;

        // reading the last margin char
        if (this.marginCharsRead === MARGIN_SIZE) {
          this.marginCharsRead = 0;
          this.status = 'WAITING';

          this.handleScannedUuid();
        }
      }
    } else if (this.status === 'READING_UUID') {
      this.uuid += getChar(event);
    } else if (this.status === 'WAITING') {
      this.marginCharsRead = 0;
    }
  }

  reset() {
    this.marginCharsRead = 0;
    this.status = 'WAITING';
    this.uuid = '';
  }

  handleScannedUuid() {
    console.log('SEND UUID:', this.uuid.trim(), typeof this.uuid);
    if (1 === 1) {
      const tid = setTimeout(
        () => this.addNotification('success', TRANSLATIONS.qrCodeSuccessRead),
        1000,
      );

      client.mutate({
        mutation: HANDLE_SCANNED_ID_MUTATION,
        variables: { uuid: this.uuid, },
      })
        .then((result) => {
          clearTimeout(tid);
          this.addNotificationResult(result.data.handleScannedId);
          this.reset();
        })
        .catch((err) => {
          clearTimeout(tid);
          // eslint-disable-next-line no-console
          console.log('HandleScannedId error:', err);
          this.addNotification('error', TRANSLATIONS.processingError, err.message);
          this.reset();
        });
    } else {
      // eslint-disable-next-line no-console
      console.log('Invalid UUID scanned: ', this.uuid);
      this.addNotification('warning', TRANSLATIONS.invalidQrCode);
      this.reset();
    }
  }

  addNotificationResult({ status, }) {
    switch (status) {
      case 'NOT_FOUND':
        return this.addNotification('warning', TRANSLATIONS.resultNotFound);
      case 'UNAUTHORIZED':
        return this.addNotification('warning', TRANSLATIONS.resultUnauthorized);
      case 'NOT_CALLED':
        return this.addNotification('warning', TRANSLATIONS.resultNotCalled);
      case 'ALREADY_FINISHED':
        return this.addNotification('warning', TRANSLATIONS.resultAlreadyFinished);
      case 'CLEARING':
        return this.addNotification('success', TRANSLATIONS.resultClearing);
      case 'CLEARED':
        return this.addNotification('success', TRANSLATIONS.resultCleared);
      case 'INVALID_STATE':
      default:
        return this.addNotification('warning', TRANSLATIONS.resultInvalidState);
    }
  }

  addNotification(status, title, text = '') {
    const { addNotification, } = this.props;
    addNotification({ status, title, text, });
  }

  render() {
    return null;
  }
}


ScannerInput.propTypes = {
  addNotification: func.isRequired,
};


export default withNotifications(ScannerInput);
