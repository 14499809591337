import React from 'react';
import {
  func, arrayOf, shape, string, object, number,
} from 'prop-types';
import styled, { withTheme, } from 'styled-components';

import { parseTimeToHuman, } from '../../../../logic/date';
import Table from '../../../../atoms/Table/Table';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import TableNoData from '../../../../atoms/Table/TableNoData';
import Bin from '../../../../styles/icons/Bin';


const COL_COUNT = 3;

const Rows = ({
  // data
  platformBlocks,
  // methods
  onDeletePlatformBlock,
}) => {
  if (platformBlocks.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        // TRANSLATION
        text="Zadne blokace"
      />
    );
  }

  return (
    <tbody>
      {platformBlocks.map((item) => (
        <tr key={item.id}>
          <td>
            {item.platformName}
          </td>

          <td>
            {parseTimeToHuman(item.from)}
            -
            {parseTimeToHuman(item.to)}
          </td>

          <td>
            <ButtonIcon
              size="sm"
              color="error"
              onClick={() => onDeletePlatformBlock(item.id)}
            >
              <Bin />
            </ButtonIcon>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

Rows.propTypes = {
  platformBlocks: arrayOf(shape({
    id: number.isRequired,
    platformId: number.isRequired,
    platformName: string.isRequired,
    from: object.isRequired,
    to: object.isRequired,
  })).isRequired,
  onDeletePlatformBlock: func.isRequired,
};


const StyledBlockingTable = styled.div`
  table {
    margin-top: 2rem;
    border-top: 2px solid ${(p) => p.theme.grey.t600};
  }
`;


const BlockingTable = ({
  // data
  platformBlocks,
  // methods
  onDeletePlatformBlock,
}) => (
  <StyledBlockingTable>
    <Table fillContent>
      <Rows
        // data
        platformBlocks={platformBlocks}
        // methods
        onDeletePlatformBlock={onDeletePlatformBlock}
      />
    </Table>
  </StyledBlockingTable>
);

BlockingTable.propTypes = {
  platformBlocks: arrayOf(shape({
    id: number.isRequired,
    platformId: number.isRequired,
    platformName: string.isRequired,
    from: object.isRequired,
    to: object.isRequired,
  })).isRequired,
  onDeletePlatformBlock: func.isRequired,
};


export default withTheme(BlockingTable);
