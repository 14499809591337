import React, { Component, } from 'react';
import { string, object, func, } from 'prop-types';

import Pagination from '../../../../components/Pagination/Pagination';
import RecordEdit from '../RecordEdit/RecordEdit';
import TableView from './TableView';
import {
  MUTATION_CONFIRM_ENTRANCE,
  MUTATION_REJECT_ENTRANCE,
} from '../../gql/mutations';
import {
  SUBSCRIPTION_ADD_RECORD_TO_GATE_ACTUAL,
  SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_ACTUAL,
} from '../../gql/subscriptions';
import YesNoModal from '../../../../components/modal/YesNoModal';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';


class TableLogic extends Component {
  state = {
    editRecordModal: {
      isOpen: false,
      id: null,
    },
    confirmEntranceRecordModal: {
      isOpen: false,
      id: null,
    },
    rejectEntranceRecordModal: {
      isOpen: false,
      id: null,
    },
  }

  componentDidMount() {
    const { queryData, } = this.props;

    queryData.subscribeToMore({
      document: SUBSCRIPTION_ADD_RECORD_TO_GATE_ACTUAL,
      updateQuery: () => {
        // refetch because of pagination
        queryData.refetch();
        return null;
      },
    });

    queryData.subscribeToMore({
      document: SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_ACTUAL,
      updateQuery: () => {
        // refetch because of pagination
        queryData.refetch();
        return null;
      },
    });
  }

  handleToggleRecordEdit = (id = null) => {
    this.setState((prevState) => ({
      editRecordModal: {
        isOpen: !prevState.editRecordModal.isOpen,
        id,
      },
    }));
  }

  handleToggleRecordConfirmEntrance = (item) => {
    const { confirmEntranceRecordModal, } = this.state;
    this.setState((prevState) => ({
      confirmEntranceRecordModal: {
        isOpen: !prevState.confirmEntranceRecordModal.isOpen,
        id: item && item.id ? item.id : null,
      },
    }));
  }

  handleRecordConfirmError = (err) => {
    const { addNotification, translations, } = this.props;
    if (err.message === 'GraphQL error: not_allowed') {
      addNotification({ title: translations.gatehouse.confirmDenied, status: 'error', });
      return;
    }
    addNotification({ title: 'Něco se nepovedlo', status: 'error', });
  }

  handleToggleRecordRejectEntrance = (id = null) => {
    this.setState((prevState) => ({
      rejectEntranceRecordModal: {
        isOpen: !prevState.rejectEntranceRecordModal.isOpen,
        id,
      },
    }));
  }

  refetchTable = () => {
    const { queryData, } = this.props;

    queryData.refetch();
  }

  render() {
    const {
      editRecordModal, confirmEntranceRecordModal, rejectEntranceRecordModal,
    } = this.state;
    const {
      // data
      filter,
      queryData,
      languageId,
      translations,
      // methods
      onChangeFilter,
      onChangeSort,
      onChangeParam,
    } = this.props;

    return (
      <>
        {editRecordModal.isOpen && (
          <RecordEdit
            // data
            recordId={editRecordModal.id}
            languageId={languageId}
            translations={translations}
            // methods
            onToggle={this.handleToggleRecordEdit}
            onEntranceResolution={this.refetchTable}
            onAssignedTransportationToGateRecord={this.refetchTable}
          />
        )}

        <YesNoModal
          // data
          isOpen={confirmEntranceRecordModal.isOpen}
          mutation={MUTATION_CONFIRM_ENTRANCE}
          variables={{ record: { id: confirmEntranceRecordModal.id, }, }}
          title={translations.gatehouse.confirmDepartureModalTitle}
          text={translations.gatehouse.confirmDepartureModalText}
          // methods
          onToggle={this.handleToggleRecordConfirmEntrance}
          onComplete={this.refetchTable}
          onError={this.handleRecordConfirmError}
        />

        <YesNoModal
          // data
          isOpen={rejectEntranceRecordModal.isOpen}
          mutation={MUTATION_REJECT_ENTRANCE}
          variables={{ id: rejectEntranceRecordModal.id, }}
          title={translations.gatehouse.rejectEntranceModalTitle}
          text={translations.gatehouse.rejectEntranceModalText}
          // methods
          onToggle={this.handleToggleRecordRejectEntrance}
          onComplete={this.refetchTable}
        />

        <TableView
            // data
          filter={filter}
          queryData={queryData}
          translations={translations}
          languageId={languageId}
            // methods
          onEdit={this.handleToggleRecordEdit}
          onConfirmEntrance={this.handleToggleRecordConfirmEntrance}
          onRejectEntrance={this.handleToggleRecordRejectEntrance}
          onChangeSort={onChangeSort}
          onChangeParam={onChangeParam}
        />

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.filterGateRecords
            ? queryData.data.filterGateRecords.filter
            : undefined
              }
          onChangePage={onChangeFilter}
        />
      </>
    );
  }
}

TableLogic.propTypes = {
  // data
  filter: object.isRequired,
  queryData: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  addNotification: func.isRequired,
  // methods
  onChangeFilter: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};

export default withNotifications(TableLogic);
