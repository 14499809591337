import gql from 'graphql-tag';

/**
 * DropDowns
 */

export const QUERY_DD_GATE_VEHICLE_GROUPS = gql`
  query FetchGateVehicleGroups($languageId: ID) {
    fetchGateVehicleGroups(languageId: $languageId) {
      id
      name
    }
  }
`;

export const QUERY_DD_GATE_VEHICLE_GROUPS_WITH_TRANSPORTATION_TYPES = gql`
  query FetchGateVehicleGroupsWithTransportationTypes($languageId: ID) {
    fetchGateVehicleGroupsWithTransportationTypes(languageId: $languageId) {
      id
      name
    }
  }
`;

export const QUERY_DD_GATEHOUSE_STATES = gql`
  query FetchAllGatehouseStates {
    fetchAllGatehouseStates {
      id
      name
    }
  }
`;

/**
 * Table queries
 */

export const QUERY_GATE_RECORDS = gql`
  query FilterGateRecords($section: String!, $languageId: ID!, $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: gateRecordInputFilterParams) {
    filterGateRecords(section: $section, languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
      }
      rows {
        id
        gateType
        time
        arrivalTime
        registrationNumber
        gateVehicleGroupId
        gateVehicleGroupName
        transportationTypeName
        transportationTypeId
        transportationStateId
        transportationId
        checkpoints {
          action
          date
        }
        inArea
        platform
      }
    }
  }
`;

export const QUERY_GATE_NOTIFICATIONS = gql`
  query FilterGateNotifications($languageId: ID!, $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: gateNotificationInputFilterParams) {
    filterGateNotifications(languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
      }
      rows {
        id
        message
        createdAt
        registrationNumber
        transportationTypeName
        transportationTypeId
        transportationId
      }
    }
  }
`;

export const QUERY_GATE_RECORD_DETAIL = gql`
  query fetchOneGateRecordRecord($id: ID, $languageId: ID) {
    fetchOneGateRecord(id: $id, languageId: $languageId) {
      id
      driverName
      driverPhoneNumber
      transportationId
      transportationNo
      transportationStateId
      transportationTypeName
      transportationTypeId
      gateVehicleGroupId
      gateVehicleGroupName
      registrationNumber
      trailerRegistrationNumber
      gateType
      time
      picture
      note
      platform
      checkpoints {
        action
        date
      }
      inArea
    }
  }
`;

export const QUERY_FIND_TRANSPORTATION = gql`
  query FindTransportation($languageId: ID, $gateType: String, $transportationNo: String, $registrationNumber: String) {
    findTransportation(languageId: $languageId, gateType: $gateType, transportationNo: $transportationNo, registrationNumber: $registrationNumber) {
      driverName
      driverPhoneNumber
      transportationId
      transportationNo
      transportationStateId
      transportationTypeName
      registrationNumber
      trailerRegistrationNumber
      platform
      checkpoints {
        action
        date
      }
      hasQualityControl
    }
  }
`;

/**
 * Records in tab count
 */

export const QUERY_GATE_ACTUAL_RECORD_COUNT = gql`
  query fetchGateActualRecordCount {
    fetchGateActualRecordCount
  }
`;

export const QUERY_GATE_IN_AREA_RECORD_COUNT = gql`
  query fetchGateInAreaRecordCount {
    fetchGateInAreaRecordCount
  }
`;

export const QUERY_GATE_NOTIFICATIONS_RECORD_COUNT = gql`
  query fetchGateNotificationsRecordCount {
    fetchGateNotificationsRecordCount
  }
`;
