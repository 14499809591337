import React from 'react';
import {
  shape, string, func, object, arrayOf, number,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { RESERVATION_TYPES, } from '../../../../globals';
import { QUERY_DD_PLATFORMS, QUERY_DD_TRANSPORTATION_TYPES, } from '../../gql/queries';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import InputTimeSelect from '../../../../atoms/InputTimeSelect/InputTimeSelect';
import TextArea from '../../../../atoms/TextArea/TextArea';


const TransportationReservationForm = ({
  form,
  optionsReservationTypes,
  languageId,
  values,
  translations,
  onChange,
}) => (
  <>
    <FormRow
      label={translations.form.reservationNumber}
      input={form.resNo}
    >
      <Input
        type="text"
        value={values.resNo}
        onChange={(e) => onChange(form.resNo.name, e.target.value)}
        status={form.resNo.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.transportationType}
      input={form.resTransportationType}
    >
      <Query
        query={QUERY_DD_TRANSPORTATION_TYPES}
        variables={{ languageId, }}
      >
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.resTransportationType}
            options={
              (data && data.fetchAllTransportationTypes)
                ? data.fetchAllTransportationTypes
                : []
            }
            onChange={(option) => onChange(form.resTransportationType.name, option)}
            status={form.resTransportationType.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isClearable
            isLoading={loading}
            error={error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>

    <FormRow
      label={translations.form.reservationType}
      input={form.resReservationType}
    >
      <InputSelect
        value={values.resReservationType}
        options={optionsReservationTypes}
        placeholder={translations.common.select}
        onChange={(option) => onChange(form.resReservationType.name, option)}
        status={form.resReservationType.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
      />
    </FormRow>

    {(
      values.resReservationType
      && values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
    ) && (
      <FormRow
        label={translations.form.platform}
        input={form.resPlatform}
      >
        <Query
          query={QUERY_DD_PLATFORMS}
          variables={{
            languageId,
            transportationTypeId: values.resTransportationType
              ? values.resTransportationType.id
              : undefined,
          }}
          skip={!values.resTransportationType}
        >
          {({ data, loading, error, }) => (
            <InputSelect
              value={values.resPlatform}
              options={(data && data.fetchAllPlatforms)
                ? data.fetchAllPlatforms
                : []
              }
              onChange={(option) => onChange(form.resPlatform.name, option)}
              status={form.resPlatform.status}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={loading}
              error={error}
              placeholder={translations.transportation.placeholderPlatform}
            />
          )}
        </Query>
      </FormRow>
    )}

    {(
      values.resReservationType
      && values.resReservationType.id === RESERVATION_TYPES.DAY
    ) && (
      <Row>
        <Col XS={12} SM={12}>
          <FormRow
            label={translations.form.date}
            input={form.resDate}
          >
            <InputDateSelect
              value={values.resDate}
              onChange={(newDate) => onChange(form.resDate.name, newDate)}
              status={form.resDate.status}
            />
          </FormRow>
        </Col>
        <Col XS={12} SM={12}>
          <FormRow
            label={translations.transportation.labelReservationOnDayAproxArrivalTime}
            input={form.resDate}
          >
            <InputTimeSelect
              value={values.resDate}
              showTime
              onChange={(newTime) => onChange(form.resDate.name, newTime)}
              status={form.resDate.status}
            />
          </FormRow>
        </Col>
      </Row>
    )}

    {(
      values.resReservationType
      && (
        values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
        || values.resReservationType.id === RESERVATION_TYPES.INTERVAL
      )
    ) && (
      <>
        <FormRow
          label={translations.common.from}
          input={form.resTimeFrom}
        >
          <Row>
            <Col XS={12} SM={12}>
              <InputDateSelect
                value={values.resTimeFrom}
                showTime
                onChange={(newDate) => onChange(form.resTimeFrom.name, newDate)}
                status={form.resTimeFrom.status}
              />
            </Col>
            <Col XS={12} SM={12}>
              <InputTimeSelect
                value={values.resTimeFrom}
                showTime
                onChange={(newTime) => onChange(form.resTimeFrom.name, newTime)}
                status={form.resTimeFrom.status}
              />
            </Col>
          </Row>
        </FormRow>

        <FormRow
          label={translations.form.to}
          input={form.resTimeTo}
        >
          <Row>
            <Col XS={12} SM={12}>
              <InputDateSelect
                value={values.resTimeTo}
                showTime
                onChange={(newDate) => onChange(form.resTimeTo.name, newDate)}
                status={form.resTimeTo.status}
              />
            </Col>
            <Col XS={12} SM={12}>
              <InputTimeSelect
                value={values.resTimeTo}
                showTime
                onChange={(newTime) => onChange(form.resTimeTo.name, newTime)}
                status={form.resTimeTo.status}
              />
            </Col>
          </Row>
        </FormRow>
      </>
    )}

    <FormRow
      label="Poznámka"
      input={form.note}
    >
      <TextArea
        type="text"
        value={values.note}
        onChange={(e) => onChange(form.note.name, e.target.value)}
        status={form.note.status}
      />
    </FormRow>
  </>
);


TransportationReservationForm.propTypes = {
  languageId: string.isRequired,
  optionsReservationTypes: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  translations: object.isRequired,
  form: shape({
    resNo: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resTransportationType: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resReservationType: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resPlatform: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resDate: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resTimeFrom: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    resTimeTo: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    note: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    resNo: string,
    resTransportationType: object,
    resReservationType: object,
    resPlatform: object,
    resDate: object,
    resTimeFrom: object,
    resTimeTo: object,
    note: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default TransportationReservationForm;
