import gql from 'graphql-tag';


export const HANDLE_SCANNED_ID_MUTATION = gql`
  mutation HandleScannedId($uuid: String) {
    handleScannedId(uuid: $uuid) {
      uuid
      status
    }
  }
`;
