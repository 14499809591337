import gql from 'graphql-tag';

/**
 * Create rs user
 */
export const MUTATION_CREATE_RS_USER = gql`
  mutation CreateRsUser($email: String!, $phoneNum: String!, $companyId: Int!, $companyName: String!, $role: String!, $languageId: Int!) {
    createRsUser(email: $email, phoneNum: $phoneNum, companyId: $companyId, companyName: $companyName, role: $role, languageId: $languageId) {
      id
    } 
  }
`;

/**
 * Update rs user
 */
export const MUTATION_UPDATE_RS_USER = gql`
  mutation UpdateRsUser($id: Int!, $email: String!, $phoneNum: String!, $companyId: Int!, $companyName: String!, $role: String!, $languageId: Int!) {
    updateRsUser(id: $id, email: $email, phoneNum: $phoneNum, companyId: $companyId, companyName: $companyName, role: $role, languageId: $languageId) {
      id
    } 
  }
`;

/**
 * Delete rs user
 */
export const MUTATION_DELETE_RS_USER = gql`
  mutation DeleteRsUser($id: Int!) {
    deleteRsUser(id: $id) 
  }
`;

/**
 * Lock rs user
 */
export const MUTATION_LOCK_RS_USER = gql`
  mutation LockRsUser($id: Int!, $active: Boolean!) {
    lockRsUser(id: $id, active: $active) {
      id
    }
  }  
`;

export const MUTATION_CREATE_PLATFORM_BLOCK = gql`
  mutation CreatePlatformBlock($platformId: Int!, $from: DateTime!, $to: DateTime!) {
    createPlatformBlock(platformId: $platformId, from: $from, to: $to)
  }
`;

export const MUTATION_DELETE_PLATFORM_BLOCK = gql`
  mutation DeletePlatformBlock($id: Int!) {
    deletePlatformBlock(id: $id)
  }
`;

export const MUTATION_DELETE_RESERVATION = gql`
  mutation deleteTransportation($id: ID!) {
    deleteTransportation(id: $id)
  }
`;
