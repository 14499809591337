import React from 'react';
import {
  arrayOf, shape, string, func, object, bool,
} from 'prop-types';
import styled from 'styled-components';
import Calendar from 'react-calendar';

import {
  formatMonthYear,
  formatWeekdayShort,
  getDayActuality,
  getDayAvailability,
  getFirstDateOfMonth,
  getLastDateOfNextYear,
} from './utils';

const StyledBlockingCalendar = styled.div`
  .calendar {
    border: none;
   
    .day-tile-wrapper {
      padding: 0;
      margin: 0;
      background-color: transparent;
  
      abbr {
        display: none;
      }
    }
  
    .react-calendar__navigation {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    
      height: 2rem;
      margin-bottom: 0.2rem;
  
      background-color: ${(p) => p.theme.grey.t800};
      color: ${(p) => p.theme.white};
      
      button {
        border: none;
      }
  
      *, *:disabled {
        background-color: inherit;
        color: inherit;
        font-size: 1.5rem;
      }
      
      .react-calendar__navigation__arrow {
        width: 2rem;
        cursor: pointer;
      }
  
      .react-calendar__navigation__arrow:disabled {
        color: transparent;
      }
  
      .react-calendar__navigation__arrow:not(:disabled):hover {
        background-color: ${(p) => p.theme.grey.t900};
      }
    }
  
    .react-calendar__month-view__weekdays {
      height: 2rem;
  
      background-color: ${(p) => p.theme.grey.t600};
  
      abbr {
        color: ${(p) => p.theme.white};
        text-decoration: none;
      }
    }
    
    .react-calendar__month-view__weekdays__weekday {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .day-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    height: 5rem;
  
    padding: 0.4rem;
    border: 0.2rem solid ${(p) => p.theme.white};
    font-size: 1rem;
    
    cursor: pointer;
    
    &.actual {
      border-color: ${(p) => p.theme.grey.t400};
    }
    
    &.selected, &:hover {
      border-color: ${(p) => p.theme.grey.t600};
      filter: brightness(85%);
    }
  }
  
  .day-tile.available {
    background-color: ${(p) => p.theme.tertiary.t100};
  }
  
  .day-tile.partially-blocked {
    background-color: ${(p) => p.theme.warning.t500};
  }
  
  .day-tile.blocked {
    background-color: ${(p) => p.theme.error.t800};
  }
  
  .calendar-legends {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    width: 100%;
    margin-top: 1rem;
  }
  
  .legend-circle {
    display: inline-block;
    
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
  }
  
  .legend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.available > .legend-circle {
      background-color: ${(p) => p.theme.tertiary.t100};
    }
    
    &.partially-blocked > .legend-circle {
      background-color: ${(p) => p.theme.warning.t500};
    }
    
    &.blocked > .legend-circle {
      background-color: ${(p) => p.theme.error.t800};
    }
  }
`;

const BlockingCalendar = ({
  // data
  actualDate,
  displayedDate,
  selectedDate,
  blockedDays,
  translations,
  translationsCalendar,
  // methods
  onDateClick,
  onDisplayedDateChange,
}) => (
  <StyledBlockingCalendar>
    <Calendar
      className="calendar"
      onChange={onDateClick}
      activeStartDate={displayedDate}
      onActiveStartDateChange={({ activeStartDate, }) => onDisplayedDateChange(activeStartDate)}
      value={undefined}
      // render custom tile content
      tileClassName={({ date, }) => `
        day-tile
        ${getDayAvailability(date, blockedDays)}
        ${getDayActuality(date, actualDate, selectedDate)}
      `}
      // formats month and weekdays
      formatMonthYear={(_, date) => formatMonthYear(date, translationsCalendar)}
      formatShortWeekday={(_, date) => formatWeekdayShort(date, translationsCalendar)}
      // sets displayable content
      minDetail="month"
      minDate={getFirstDateOfMonth(actualDate)}
      maxDate={getLastDateOfNextYear(actualDate)}
      // hide buttons switching years and days from neighboring months
      next2Label={null}
      prev2Label={null}
      showNeighboringMonth={false}
    />

    <footer className="calendar-legends">
      <span className="legend available">
        <span className="legend-circle" />
        <span className="legend-text">
          {translations.rs.platformBlocks.available}
        </span>
      </span>

      <span className="legend partially-blocked">
        <span className="legend-circle" />
        <span className="legend-text">
          {translations.rs.platformBlocks.partiallyBlocked}
        </span>
      </span>

      <span className="legend blocked">
        <span className="legend-circle" />
        <span className="legend-text">
          {translations.rs.platformBlocks.blocked}
        </span>
      </span>
    </footer>
  </StyledBlockingCalendar>
);

BlockingCalendar.propTypes = {
  actualDate: object.isRequired,
  displayedDate: object.isRequired,
  selectedDate: object.isRequired,
  blockedDays: arrayOf(shape({
    date: string.isRequired,
    fullyBlocked: bool.isRequired,
  })).isRequired,

  onDateClick: func.isRequired,
  onDisplayedDateChange: func.isRequired,

  translations: object.isRequired,
  translationsCalendar: object.isRequired,
};

export default BlockingCalendar;
