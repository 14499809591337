import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_DD_LANGUAGES, } from '../../gql/queries';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';

const TransportationDriverForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.personName}
      input={form.driverName}
    >
      <Input
        type="text"
        value={values.driverName}
        onChange={(e) => onChange(form.driverName.name, e.target.value)}
        status={form.driverName.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.phoneNumber}
      input={form.driverPhoneNumber}
    >
      <Input
        type="text"
        value={values.driverPhoneNumber}
        onChange={(e) => onChange(form.driverPhoneNumber.name, e.target.value)}
        status={form.driverPhoneNumber.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.language}
      input={form.languageId}
    >
      <Query
        query={QUERY_DD_LANGUAGES}
        variables={{ }}
      >
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.languageId}
            options={
              (data && data.fetchLanguages)
                ? data.fetchLanguages
                : []
            }
            onChange={(option) => onChange(form.languageId.name, option)}
            status={form.languageId.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isClearable
            isLoading={loading}
            error={error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>

  </Fragment>
);


TransportationDriverForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    driverName: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    driverPhoneNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    languageId: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    driverName: string,
    driverPhoneNumber: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default TransportationDriverForm;
