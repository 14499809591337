import { INPUT_TYPE, } from 'sofair-form-validation';

import { initForm, initInput, } from '../../../../logic/form/common';


export const finishFormStructure = {
  ...initForm,
  form: {
    solve: initInput({
      name: 'solve',
      type: INPUT_TYPE.CHECKBOX,
    }),
    manualRedirect: initInput({
      name: 'manualRedirect',
      type: INPUT_TYPE.CHECKBOX,
    }),
    stand: initInput({
      name: 'stand',
      type: INPUT_TYPE.SELECT,
    }),
  },
  values: {
    solve: false,
    manualRedirect: false,
    stand: null,
  },
};


export const forwardFormStructure = {
  ...initForm,
  form: {
    platform: initInput({
      name: 'platform',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
  },
  values: {
    platform: null,
  },
};

export const smsFormStructure = {
  detailForm: {
    ...initForm,
    form: {
      text: initInput({
        name: 'text',
        type: INPUT_TYPE.TEXT,
        validation: {
          required: true,
          max: 200,
          min: 2,
        },
      }),
    },
    values: {
      text: '',
    },
  },
};
