import gql from 'graphql-tag';


/**
 * Settings
 */
export const TRANSLATIONS_SETTINGS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          form {
            username
            name
            blocked
            roles
          }
          settings {
            rolesTitle
            createRole
            usersTitle
            createUser
            userUnlocked
            userBlocked
            removeUserTitle
            removeUserText
            block
            unblock
            thRoles
            thBlocked
            thUsername
            thActions
            thName
            rolesEmpty
            usersEmpty
          }
          common {
            settings
            delete
            yes
            no
            detail
            home
          }
        }
      }
    }
  }
`;


/**
 * Roles
 */
export const TRANSLATIONS_ROLE = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          form {
            name
          }
          common {
            created
            updated
            back
            create
            update
          }
          settings {
            createRoleTitle
            detailRoleTitle
            allResources
          }
        }
      }
    }
  }
`;

export const TRANSLATIONS_ROLE_DELETE = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            delete
            deleted
            back
          }
          settings {
            removeRoleTitle
            removeRoleText
            removeRoleForceTitle
            removeRoleForceText
            removeRoleForce
            removeRoleForceUsersHeader
          }
        }
      }
    }
  }
`;


/**
 * users
 */
export const TRANSLATIONS_USER = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          form {
            name
            username
            password
            chip
            pin
            roles
            blocked
            newPassword
          }
          common {
            created
            updated
            back
            create
            update
          }
          settings {
            createUserTitle
            detailUserTitle
          }
        }
      }
    }
  }
`;


/**
 * SMS
 */
export const QUERY_SMS_VIEW_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            filter
            home
            detail
            smsAdministration
          }
          sms {
            infoSmsEmpty
            language
            type
          }
        }
      }
    }
  }
`;

export const QUERY_SMS_DETAIL_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            update
            updated
          }
          sms {
            insertPlaceholder
            language
            placeholders
            text
            titleUpdateModalSms
            type
          }
        }
      }
    }
  }
`;
