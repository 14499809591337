import React, { Component, } from 'react';
import {
  shape, string, func, object, arrayOf, bool,
} from 'prop-types';

import { withApollo, } from 'react-apollo';
import { FetchPolicy, } from 'apollo-client/core/watchQueryOptions';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
  changeMultipleValues,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { parseConfirmEntranceMutationVariables, } from '../utils';
import { initRecordForm, } from '../forms/recordStructure';
import RecordEditView from './RecordEditView';
import { gateTypeToTranslationKey, } from '../enums/gateTypeEnum';
import { QUERY_FIND_TRANSPORTATION, } from '../../gql/queries';
import { MUTATION_ASSIGN_TRANSPORTATION_TO_GATE_RECORD, } from '../../gql/mutations';


class RecordEditLogic extends Component {
  constructor(props) {
    super(props);

    const { data, translations, } = this.props;

    this.state = {
      detailForm: {
        ...initRecordForm,
        values: {
          ...initRecordForm.values,
          ...data,
          time: data.time ? new Date(data.time) : null,
          gateType: data.gateType ? {
            id: data.gateType,
            name: translations.form[gateTypeToTranslationKey(data.gateType)],
          } : null,
          gateVehicleGroupId: data.gateVehicleGroupId ? {
            id: data.gateVehicleGroupId,
            name: data.gateVehicleGroupName,
          } : null,
        },
      },
    };
  }

  handleChangeForm = (name, value) => {
    const { detailForm, } = this.state;

    const newDetailForm = changeAndValidateInput(detailForm, name, value);
    this.setState({ detailForm: newDetailForm, });
  }

  handleFillFormValues = (newValues) => {
    const { detailForm, } = this.state;

    const newDetailForm = changeMultipleValues(detailForm, newValues);
    this.setState({ detailForm: newDetailForm, });
  }

  handleConfirmEntrance = (confirmMutation) => {
    const { detailForm, detailForm: { values, }, } = this.state;
    const { isEditMode, } = this.props;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      confirmMutation({
        variables: {
          record: parseConfirmEntranceMutationVariables(values, isEditMode),
        },
      });
    }
  }

  handleConfirmEntranceError = (mutationError) => {
    try {
      const { detailForm, } = this.state;
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        if (message === 'UNPROCESSABLE_ENTITY' && extensions.exception.data) {
          this.setState({
            detailForm: mergeValidationObjectIntoForm(detailForm, extensions.exception.data),
          });
        }
        if (message === 'not_allowed') {
          const { addNotification, translations, } = this.props;
          addNotification({ title: translations.gatehouse.confirmDenied, status: 'error', });
        }
      }
    } catch {
      // continue regardless of error
    }
  }

  handleRejectEntrance = (confirmMutation) => {
    const { detailForm, } = this.state;

    confirmMutation({
      variables: {
        id: detailForm.values.id,
      },
    });
  }

  handleEntranceResolutionComplete = (title) => {
    const {
      onToggle,
      addNotification,
      onEntranceResolution,
    } = this.props;

    onToggle();
    addNotification({ title, status: 'success', });
    onEntranceResolution();
  }

  handleFindTransportation = async (args) => {
    const { detailForm, } = this.state;
    const {
      client, languageId, addNotification, translations, isEditMode, data, onAssignedTransportationToGateRecord,
    } = this.props;

    if (!detailForm.values.gateType) {
      addNotification({ title: translations.gatehouse.findTransportationNoDirection, status: 'error', });
      return;
    }

    if (!(args.registrationNumber || args.transportationNo)) {
      addNotification({ title: translations.gatehouse.findTransportationNoRNOrTransportationNo, status: 'error', });
      return;
    }

    const findQuery = await client.query({
      query: QUERY_FIND_TRANSPORTATION,
      variables: { languageId, gateType: detailForm.values.gateType.id, ...args, },
      fetchPolicy: 'network-only',
    });

    if (!findQuery.data.findTransportation) {
      addNotification({ title: translations.gatehouse.findTransportationNotFound, status: 'error', });
      return;
    }

    detailForm.values = { ...detailForm.values, ...findQuery.data.findTransportation, };
    this.setState({ detailForm, });

    if (isEditMode && data.id) {
      await client.mutate({
        mutation: MUTATION_ASSIGN_TRANSPORTATION_TO_GATE_RECORD,
        variables: {
          id: data.id,
          transportationId: findQuery.data.findTransportation.transportationId,
        },
      });
      onAssignedTransportationToGateRecord(data.id);
    }
    addNotification({ title: translations.gatehouse.findTransportationFound, status: 'success', });
  }

  render() {
    const { detailForm, } = this.state;
    const {
      languageId, translations, onToggle, isEditMode,
    } = this.props;

    return (
      <RecordEditView
        // data
        isEditMode={isEditMode}
        detailForm={detailForm}
        languageId={languageId}
        translations={translations}
        // methods
        onToggle={onToggle}
        onChangeForm={this.handleChangeForm}
        onFindTransportation={this.handleFindTransportation}
        onFillFormValues={this.handleFillFormValues}

        onConfirmEntrance={this.handleConfirmEntrance}
        onConfirmEntranceComplete={
          () => this.handleEntranceResolutionComplete(translations.gatehouse.entranceConfirmed)
        }
        onConfirmEntranceError={this.handleConfirmEntranceError}
        onRejectEntrance={this.handleRejectEntrance}
        onRejectEntranceComplete={
          () => this.handleEntranceResolutionComplete(translations.gatehouse.entranceRejected)
        }
        onRejectEntranceError={console.error}
      />
    );
  }
}

RecordEditLogic.defaultProps = {
  onAssignedTransportationToGateRecord: () => {},
};

RecordEditLogic.propTypes = {
  // data
  data: shape({
    // basic
    id: string,
    driverName: string,
    driverPhoneNumber: string,
    gateVehicleGroupId: string,
    gateVehicleGroupName: string,
    registrationNumber: string,
    gateType: string,
    gateTypeName: string,
    time: string,
    // extra
    rnPicture: string,
    note: string,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  onEntranceResolution: func.isRequired,
  onAssignedTransportationToGateRecord: func,
  isEditMode: bool.isRequired,
};


export default withApollo(withNotifications(RecordEditLogic));
