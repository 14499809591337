import React from 'react';
import {
  arrayOf, string, object, func,
} from 'prop-types';

import Button from '../../../../atoms/Button/Button';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import EditLogic from './EditLogic';
import FlowChart from './FlowChart';
import Actions from './Actions';

import StyledTransportationAdmin from './styles/StyledTransportationAdmin';

const mergePictures = (detail, type) => {
  const {
    gateInPict,
    gateOutPict,
    cargoSpacePictIn,
    cargoSpacePictOut,
    otherPictsIn,
    otherPictsOut,
  } = detail;

  const pictures = [];

  if (type === 'in') {
    if (gateInPict) {
      pictures.push(gateInPict);
    }
    if (cargoSpacePictIn) {
      pictures.push(cargoSpacePictIn);
    }
    if (otherPictsIn) {
      pictures.push(...otherPictsIn);
    }
  } else {
    if (gateOutPict) {
      pictures.push(gateOutPict);
    }
    if (cargoSpacePictOut) {
      pictures.push(cargoSpacePictOut);
    }
    if (otherPictsOut) {
      pictures.push(...otherPictsOut);
    }
  }

  return pictures;
};

const TransportationAdminView = ({
  // data
  detail,
  tabs,
  transportationId,
  languageId,
  translations,
  resources,
  // methods
  onToggle,
  onEdited,
  onDeleted,
}) => (
  <StyledTransportationAdmin>

    <Actions
      detail={detail}
      translations={translations}
      resources={resources}
      onToggle={onToggle}
      onDeleted={onDeleted}
    />

    <EditLogic
      data={detail}
      translations={translations}
      languageId={languageId}
      onEdited={onEdited}
    />

    <FlowChart
      tabs={tabs}
      gateIn={detail.gateHistoryIn}
      gateOut={detail.gateHistoryOut}
      gateHistoryInTime={detail.gateHistoryInTime}
      gateHistoryOutTime={detail.gateHistoryOutTime}
      transportationId={transportationId}
      picturesIn={mergePictures(detail, 'in')}
      picturesOut={mergePictures(detail, 'out')}
      translations={translations}
      languageId={languageId}
    />

    <Row>
      <Col>
        <Button
          onClick={onToggle}
          shape="outline"
        >
          {translations.common.close}
        </Button>
      </Col>
    </Row>

  </StyledTransportationAdmin>
);


TransportationAdminView.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  detail: object.isRequired,
  tabs: arrayOf(object).isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onEdited: func,
  onDeleted: func,
};

TransportationAdminView.defaultProps = {
  onEdited: undefined,
  onDeleted: undefined,
};


export default TransportationAdminView;
