export const getRoles = (translations) => [
  { id: 'driver', name: translations.rs.users.driver, },
  { id: 'dispatcher', name: translations.rs.users.dispatcher, },
];

export const getRoleText = (role, translations) => {
  if (role === 'driver') {
    return translations.rs.users.driver;
  }
  if (role === 'dispatcher') {
    return translations.rs.users.dispatcher;
  }
  return '-';
};
