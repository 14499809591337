import React, { Component, } from 'react';
import { func, } from 'prop-types';

import { MESSAGES, GraphQLErrorsGlobal, } from '../../../globals';
import { loginSetTokenResetConnection, } from '../../../logic/graphql/apollo';
import { withErrors, } from '../../../logic/errorManager/withErrors';
import {
  initForm, initInput, changeAndValidateInput, validateAndMergeWholeForm,
} from '../../../logic/form/common';
import LoginView from '../components/Login/LoginView';


class LoginPage extends Component {
  state = {
    loginForm: {
      ...initForm,
      form: {
        username: initInput({
          name: 'username',
          validation: {
            required: true,
          },
        }),
        password: initInput({
          name: 'password',
          validation: {
            required: true,
          },
        }),
      },
      values: {
        username: '',
        password: '',
      },
    },
  }


  handleKey = (e, login) => {
    if (e.key === 'Enter') {
      login();
    }
  }


  handleValueChange = (name, value) => {
    const { loginForm, } = this.state;

    const newState = changeAndValidateInput(loginForm, name, value);
    this.setState({
      loginForm: newState,
    });
  }


  handleLogin = (mutationLogin) => {
    const { loginForm, loginForm: { values, }, } = this.state;

    const newState = validateAndMergeWholeForm(loginForm);

    if (!newState.isValid) {
      this.setState({
        loginForm: newState,
      });
    } else {
      mutationLogin({
        variables: {
          username: values.username,
          password: values.password,
        },
      });
    }
  }


  handleLoginCompleted = (data) => {
    const {
      history,
      location,
    } = this.props;
    const { from, } = location.state || { from: { pathname: '/', }, };

    if (!data || !data.login) return;

    loginSetTokenResetConnection(data.login.accessToken);
    history.push(from);
  }


  parseGraphQLError = (error) => {
    const { translateErrorCode, } = this.props;
    let returnErrorCode = MESSAGES.SOMETHING_HAPPENED;

    try {
      const { graphQLErrors, networkError, } = error;

      if (networkError) {
        returnErrorCode = MESSAGES.NOT_CONNECTED;
      }

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, } = graphQLErrors[0];

        switch (message) {
          case GraphQLErrorsGlobal.UNAUTHORIZED: {
            returnErrorCode = MESSAGES.BAD_CREDENTIALS;
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      returnErrorCode = MESSAGES.SOMETHING_HAPPENED;
    }

    return translateErrorCode(returnErrorCode);
  }


  render() {
    const { loginForm, } = this.state;

    return (
      <LoginView
         // data
        {...loginForm}
         // methods
        onChange={this.handleValueChange}
        onKey={this.handleKey}
        onLogin={this.handleLogin}
        onLoginCompleted={this.handleLoginCompleted}
        parseGraphQLError={this.parseGraphQLError}
      />
    );
  }
}


LoginPage.propTypes = {
  translateErrorCode: func.isRequired,
};


export default withErrors(LoginPage);
