import gql from 'graphql-tag';


/**
 * Common
 */
export const QUERY_STANDS = gql`
  query FetchStands($languageId: ID) {
    fetchStands(languageId: $languageId) {
      id
      name
      actions {
        id
        type
      }
    }
  }
`;


export const QUERY_ENUM_DROPDOWN = gql`
  query FetchDropDown($languageId: ID, $type: String) {
    fetchDropDown(languageId: $languageId, type: $type) {
      id
      data {
        id
        name
      }
    }
  }
`;


/**
 * Stand
 */
export const QUERY_STAND_CONFIGURATION = gql`
  query FetchStandDashboardConfiguration($standId: ID) {
    fetchStandDashboardConfiguration(standId: $standId) {
      standId
      createButton
      visitorTab {
        name
        enable
        columns
        main
        table {
          attribute
          type
          sortable {
            enable
          }
          filtrable {
            enable
            query
            type
          }
        } 
      }
      tabs {
        name
        enable
        columns
        main
        callButton
        table {
          attribute
          type
          sortable {
            enable
          }
          filtrable {
            enable
            query
            type
          }
        } 
      }
    }
  }
`;


const getColumns = (columns) => {
  let params = '';
  for (let i = 0; i < columns.length; i++) {
    params += `${columns[i]}
    `;
  }
  return params;
};


export const QUERY_TRANSPORTATION_TABLE_DATA = (columns) => {
  const params = getColumns(columns);
  const query = `
    query FetchStandDashboardTransportationData($languageId: ID, $standId: ID, $type: String) {
      fetchStandDashboardTransportationData(languageId: $languageId, standId: $standId, type: $type) {
        rows {
          id
          active
          ${params}
        }
      }
    }
  `;
  return gql(query);
};


export const QUERY_VISITORS = (columns) => {
  const params = getColumns(columns);
  const query = `
    query FetchStandDashboardVisitorsData {
      fetchStandDashboardVisitorsData {
        rows {
          id
          ${params}
        }
      }
    }
  `;
  return gql(query);
};


/**
 * Stand Wizard
 */
export const QUERY_STAND_WIZARD_CONFIG = gql`
  query FetchStandActionsTabsConfiguration($languageId: ID, $standId: ID!, $transportationId: ID!) {
    fetchStandActionsTabsConfiguration(languageId: $languageId, standId: $standId, transportationId: $transportationId) {
      manualMoveToNextStandsPossible
      tabs {
        genericFormId
        title
        completed
        actionId
      }
    }
  }
`;


export const QUERY_STAND_WIZARD_GENERIC_FORM = gql`
  query FetchGenericFormConfiguration($languageId: ID, $actionId: ID!, $genericFormId: ID, $transportationId: ID!, $standId: ID!) {
    fetchTransportationEditForm(languageId: $languageId, actionId: $actionId, transportationId: $transportationId) {
      transportationEditForm {
        config
        translation
      }
      transportationEditFormValues
    }
    fetchGenericFormConfiguration(languageId: $languageId, actionId: $actionId, genericFormId: $genericFormId, transportationId: $transportationId, standId: $standId) {
      genericForm {
        id
        name
        structure
        translation
        inputs {
          name
          type
          disabled
          defaultValue
          action
          map
          index
          genericFormDialId
          translation {
            label
            placeholder
            help
          }
          validation {
            required
            min
            max
            regex {
              name
              expression
              code
              params
            }
          }
        }
      }
      genericFormValues
    }
  }
`;

export const QUERY_STAND_WIZARD_NEXT_STANDS = gql`
  query FetchStandsForNextAction($languageId: ID, $standId: ID!, $transportationId: ID!) {
    fetchStandsForNextAction(languageId: $languageId, standId: $standId, transportationId: $transportationId) {
      id
      name
    }
  }
`;
