import React, { Component, } from 'react';
import {
  number, shape, string, object, oneOfType, arrayOf, func, bool,
} from 'prop-types';

import FilteredTableLocal, { PARAMS_TYPES, VALUE_TYPES, } from '../../../../components/Table/FilteredTableLocal';
import DynamicTableView from './DynamicTableView';


export const CALL_STATES = {
  WAITING: 'waiting',
  CALLED: 'called',
  CLEARING: 'clearing',
};


class DynamicTableSettings extends Component {
  parseParams = () => {
    const { tabData: { table, }, } = this.props;
    const parsedParams = {};
    const parsedValueTypes = {};

    for (let i = 0; i < table.length; i++) {
      const param = table[i];

      // valueTypes
      parsedValueTypes[param.attribute] = param.type;

      // params
      if (param.filtrable.enable) {
        parsedParams[param.attribute] = {
          type: param.filtrable.type,
          from: param.filtrable.from ? param.filtrable.from : null,
          to: param.filtrable.to ? param.filtrable.to : null,
          value: (param.filtrable.type === PARAMS_TYPES.TEXT ? '' : null),
        };
      }
    }

    return {
      parsedParams,
      parsedValueTypes,
    };
  };


  /**
   * Parse Row Values
   */
  parseRows = (rows) => {
    const { translations, tabData: { table, }, } = this.props;
    // rows
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];

      // params
      for (let p = 0; p < table.length; p++) {
        const { type, attribute, } = table[p];

        // callState
        if (attribute === 'callState') {
          let newCallState = '';

          switch (row[attribute]) {
            case CALL_STATES.WAITING: {
              newCallState = translations.stand.callStates.waiting;
              break;
            }
            case CALL_STATES.CALLED: {
              newCallState = translations.stand.callStates.called;
              break;
            }
            case CALL_STATES.CLEARING: {
              newCallState = translations.stand.callStates.clearing;
              break;
            }
            default: {
              break;
            }
          }
          row.callStateId = row[attribute];
          row[attribute] = newCallState;
          continue; // eslint-disable-line
        }
        // date type
        if (
          (type === VALUE_TYPES.DATE || type === VALUE_TYPES.DATETIME)
          && row[attribute] !== null && row[attribute] !== undefined
        ) {
          row[attribute] = new Date(row[attribute]);
        }
      }
    }
    return rows;
  }


  render() {
    const {
      // data
      subscribeVariables,
      languageId,
      standId,
      stand,
      translations,
      translationsCols,
      actionNameActive,
      actionNameInactive,
      query,
      variables,
      getRows,
      tabData: {
        table,
        main,
        callButton,
      },
      // methods
      onDetail,
      onAction,
      getRowsSubscription,
    } = this.props;

    const { parsedParams, parsedValueTypes, } = this.parseParams();

    return (
      <FilteredTableLocal
        filter={{
          offset: 0,
          limit: 20,
          sortBy: '',
          order: '',
          params: parsedParams,
        }}
        valueTypes={parsedValueTypes}
        query={query}
        parseRows={this.parseRows}
        variables={variables}
        getRows={getRows}
        getRowsSubscription={getRowsSubscription}
      >
        {(tableProps) => (
          <DynamicTableView
            // data
            {...tableProps}
            subscribeVariables={subscribeVariables}
            tableConfig={table}
            translations={translations}
            translationsCols={translationsCols}
            actionNameActive={actionNameActive}
            actionNameInactive={actionNameInactive}
            standId={standId}
            stand={stand}
            languageId={languageId}
            main={main}
            callButton={callButton}
            // methods
            onDetail={onDetail}
            onAction={onAction}
          />
        )}
      </FilteredTableLocal>
    );
  }
}


DynamicTableSettings.propTypes = {
  subscribeVariables: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCols: object.isRequired,
  actionNameActive: string.isRequired,
  actionNameInactive: string.isRequired,
  standId: oneOfType([ string, number, ]).isRequired,
  stand: object.isRequired,
  query: object.isRequired,
  tabData: shape({
    columns: arrayOf(string).isRequired,
    table: arrayOf(shape({
      attribute: string.isRequired,
      type: string.isRequired,
      sortable: object.isRequired,
      filtrable: object.isRequired,
    })).isRequired,
    name: string.isRequired,
    main: bool.isRequired,
    callButton: bool.isRequired,
  }).isRequired,
  getRowsSubscription: func.isRequired,
  getRows: func.isRequired,
  onDetail: func,
  onAction: func,
};

DynamicTableSettings.defaultProps = {
  onDetail: undefined,
  onAction: () => {},
};


export default DynamicTableSettings;
