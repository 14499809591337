import React, { Component, } from 'react';
import {
  func, shape, string, object, number, arrayOf,
} from 'prop-types';

import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initUserForm, } from '../UserForm/structure';
import UserUpdateView from './UserUpdateView';


class UserUpdateLogic extends Component {
  constructor(props) {
    super(props);

    const {
      id,
      email,
      companyId,
      companyName,
      active,
      role,
      phoneNum,
      languageId,
    } = props.data.fetchRsUser;

    this.state = {
      ...initUserForm,
      id,
      detailForm: {
        ...initUserForm.detailForm,
        values: {
          ...initUserForm.values,
          email,
          company: { id: companyId, name: companyName, },
          active,
          role,
          phoneNum,
          language: {
            id: languageId,
            name: props.languages.find((language) => parseInt(language.id, 10) === languageId).name,
          },
        },
      },
    };
  }


  /**
   * Form - onChange
   */
  handleValueChange = (name, value) => {
    const { detailForm, } = this.state;
    const newState = changeAndValidateInput(detailForm, name, value);

    this.setState({
      detailForm: newState,
    });
  }


  /**
   * onUpdate
   */
  handleUpdate = (mutate) => {
    const { id, detailForm, detailForm: { values, }, } = this.state;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const variables = {
        id: parseInt(id, 10),
        email: values.email,
        phoneNum: values.phoneNum,
        companyId: parseInt(values.company.id, 10),
        companyName: values.company.name,
        role: values.role.id,
        languageId: parseInt(values.language.id, 10),
      };
      mutate({
        variables,
      });
    }
  }


  /**
   * onUpdate - Completed
   */
  handleUpdateComplete = (responseData) => {
    const {
      translations, onToggle, onUpdated, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onUpdated) onUpdated(responseData);
  }


  /**
   * onUpdate - Error
   */
  handleUpdateError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(detailForm, extensions.exception.data);

              this.setState({
                detailForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  render() {
    const { detailForm, } = this.state;
    const { onToggle, translations, languages, } = this.props;

    return (
      <UserUpdateView
        // data
        detailForm={detailForm}
        translations={translations}
        languages={languages}
        // methods
        onChange={this.handleValueChange}
        onToggle={onToggle}
        onUpdate={this.handleUpdate}
        onUpdateCompleted={this.handleUpdateComplete}
        onUpdateError={this.handleUpdateError}
      />
    );
  }
}


UserUpdateLogic.propTypes = {
  data: shape({
    fetchRsUser: shape({
      id: string.isRequired,
      email: string.isRequired,
      phoneNum: string.isRequired,
      companyId: number.isRequired,
      companyName: string.isRequired,
      role: object.isRequired,
      languageId: number.isRequired,
    }).isRequired,
  }).isRequired,
  translations: object.isRequired,
  onToggle: func.isRequired,
  onUpdated: func,
  addNotification: func.isRequired,
  languages: arrayOf(shape({
    id: string,
    code: string,
    name: string,
  })).isRequired,
};

UserUpdateLogic.defaultProps = {
  onUpdated: undefined,
};


export default UserUpdateLogic;
