export const isValidArray = (array) => array && Array.isArray(array);

export const onlyUnique = (array) => [ ...new Set(array), ];

export const resizeArray = (arr, newSize, defaultValue) => {
  if (newSize < arr.length) {
    return arr.slice(0, newSize);
  }
  return [...arr, ...Array(Math.max(newSize - arr.length, 0)).fill(defaultValue)];
};
