import gql from 'graphql-tag';


export const QUERY_GATEHOUSE_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            gatehouse
            filter
            from
            to
            detail
            delete
            back
            yes
            no
          }
          form {
            time
            registrationNumber
            trailerRegistrationNo
            transportationType
            personName
            phoneNumber
            note
            gateTypeEntrance
            gateTypeExit
            status
            reservationNumber
            platform
          }
          transportation {
            thActions
            btnApplyFilter
            placeholderFilterDriver
          }
          gatehouse {
            arrival
            departure
            createRecord
            createTransportation
            confirmEntrance
            confirmEntranceModalTitle
            confirmEntranceModalText
            entranceConfirmed
            rejectEntrance
            rejectEntranceModalTitle
            rejectEntranceModalText
            entranceRejected
            transportationFromRecordCreated
            tabActual
            tabInArea
            tabHistory
            tabNotifications
            direction
            noRecords
            titleRecordEditModal
            titleBasicForm
            titleExtraForm
            titleTransportationListForm
            rnPicture
            transportationSearchLabel
            transportationSearchPlaceholder
            transportationSearchNotUsedInfo
            btnSearch
            clearancePartLabel
            clearancePartPlaceholder
            transportationListLabel
            emptyTransportationList
            deleteModalRecordTitle
            deleteModalRecordText
            btnConfirmDeparture
            confirmDepartureModalTitle
            confirmDepartureModalText
            truckInAreaStatus
            truckHistoryStatus
            truckHistoryRejectedStatus
            findTransportationByTransportationNo
            findTransportationByRN
            clearance
            findTransportationNoDirection
            findTransportationNoRNOrTransportationNo
            findTransportationNotFound
            findTransportationFound
            checkpoints
            confirmDenied
          }
          monitoring {
            plan
            register
            waiting
            clearing
            cleared
            finished
          }
        }
      }
    }
  }
`;
