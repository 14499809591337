import React from 'react';

import { clearSecondsAndMilliseconds, } from '../../../logic/date';
import WarehouseSolid from '../../../styles/icons/WarehouseSolid';

export const parseConfirmEntranceMutationVariables = (values, isEditMode) => {
  const baseValues = {
    driverName: values.driverName,
    driverPhoneNumber: values.driverPhoneNumber,
    gateVehicleGroupId: values.transportationId ? null : (values.gateVehicleGroupId ? values.gateVehicleGroupId.id : null),
    registrationNumber: values.registrationNumber,
    trailerRegistrationNumber: values.trailerRegistrationNumber,
    note: values.note,
    transportationId: values.transportationId,
  };
  if (isEditMode) {
    return {
      ...baseValues,
      id: values.id,
    };
  }
  return {
    ...baseValues,
    gateType: values.gateType.id,
    time: clearSecondsAndMilliseconds(values.time),
  };
};

const unchecked = {
  width: '2em',
  height: '2em',
  marginRight: '0.5em',
  color: '#a2a2a2',
};

const checked = { ...unchecked, color: '#008000', };
const alternativeUnchecked = { ...unchecked, color: '#800000', };

export const getActionIconForRecord = (
  checkpoints,
  action,
  extraStyle = {},
  useAlternativeUncheckedStyle = false
) => {
  const checkpoint = checkpoints.find((c) => c.action === action);
  const baseStyle = checkpoint
    ? checked
    : (useAlternativeUncheckedStyle ? alternativeUnchecked : unchecked);
  const style = { ...baseStyle, ...extraStyle, };

  if (action === 'clearance') {
    return <WarehouseSolid style={style} />;
  }
  return null;
};
