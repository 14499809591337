import gql from 'graphql-tag';


/**
 * Companies
 */
export const QUERY_COMPANIES_VIEW_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            companies
            filter
            home
            delete
            detail
          }
          directory {
            newCompany
            deleteModalCompanyTitle
            deleteCompanyModalText
            companiesEmpty
            thActions
          }
          form {
            username
            password
            name
            email
            phoneNumber
            identificationNumber
            vatNumber
            street
            city
            zip
            state
          }
        }
      }
    }
  }
`;


export const QUERY_COMPANY_DETAIL_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            create
            update
            created
            updated
          }
          directory {
            createModalCompanyTitle
            updateModalCompanyTitle
            subTitleContactInformation
            subTitleBillingInformation
          }
          form {
            name
            contactPerson
            email
            phoneNumber
            identificationNumber
            vatNumber
            street
            city
            zip
            state
            info
          }
        }
      }
    }
  }
`;


/**
 * Drivers
 */
export const QUERY_DRIVERS_VIEW_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            drivers
            filter
            home
            detail
            delete
          }
          directory {
            newDriver
            deleteModalDriverTitle
            deleteModalDriverText
            driversEmpty
            thActions
          }
          form {
            personName
            phoneNumber
          }
        }
      }
    }
  }
`;


export const QUERY_DRIVER_DETAIL_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            create
            update
            created
            updated
          }
          directory {
            createModalDriverTitle
            updateModalDriverTitle
          }
          form {
            personName
            phoneNumber
            info
          }
        }
      }
    }
  }
`;


/**
 * Truck
 */
export const QUERY_TRUCKS_VIEW_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            trucks
            filter
            home
            detail
            delete
          }
          directory {
            btnNewTruck
            titleDeleteModalTruck
            textDeleteModalTruck
            infoTrucksEmpty
            thActions
          }
          form {
            truckRegistrationNumber
            vehicleGroupName
          }
        }
      }
    }
  }
`;


export const QUERY_TRUCK_DETAIL_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            create
            update
            created
            updated
          }
          directory {
            titleCreateModalTruck
            titleUpdateModalTruck
          }
          form {
            truckRegistrationNumber
            vehicleGroupName
          }
        }
      }
    }
  }
`;


/**
 * Vehicle Groups
 */
export const QUERY_VEHICLE_GROUPS_VIEW_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            vehicleGroups
            filter
            home
            detail
            delete
          }
          directory {
            btnNewGroup
            titleDeleteModalTruck
            textDeleteModalTruck
            infoTrucksEmpty
            titleDeleteModalVehicleGroup
            textDeleteModalVehicleGroup
            infoVehicleGroupsEmpty
            thActions
          }
          form {
            name
          }
        }
      }
    }
  }
`;


export const QUERY_VEHICLE_GROUPS_DETAIL_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            create
            update
            created
            updated
          }
          directory {
            titleCreateModalVehicleGroup
            titleUpdateModalVehicleGroup
          }
          form {
            name
          }
        }
      }
    }
  }
`;
