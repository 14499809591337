import { INPUT_TYPE, } from 'sofair-form-validation';
import { initForm, initInput, } from '../../../../logic/form/common';

export const initParkingAddCarForm = {
  detailForm: {
    ...initForm,
    form: {
      registrationNumber: initInput({
        name: 'registrationNumber',
        validation: {
          required: true,
          min: 3,
          max: 20,
        },
      }),
    },
    values: {
      registrationNumber: '',
    },
  },
};
