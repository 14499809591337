import React, { Component, } from 'react';
import {
  func, arrayOf, shape, string, object,
} from 'prop-types';

import MonitoringItem from './MonitoringItem';


class MonitoringColumnList extends Component {
  componentDidMount() {
    const { subscribeToAddLiveMonitoring, subscribeToRemoveLiveMonitoring, } = this.props;

    subscribeToAddLiveMonitoring();
    subscribeToRemoveLiveMonitoring();
  }


  render() {
    const {
      // data
      columnData,
      transportations,
      translations,
      translationsCommon,
      // methods
      onDetail,
    } = this.props;

    transportations.sort((a, b) => {
      const timestampA = a.stateUpdatedAt === null ? 0 : (new Date(a.stateUpdatedAt)).getTime();
      const timestampB = b.stateUpdatedAt === null ? 0 : (new Date(b.stateUpdatedAt)).getTime();
      return timestampB - timestampA;
    });

    return (
      <ul className="monitoring--items">
        {transportations.map((item) => (
          <MonitoringItem
            // data
            key={item.id}
            columnData={columnData}
            data={item}
            translations={translations}
            translationsCommon={translationsCommon}
            // methods
            onDetail={onDetail}
          />
        ))}
      </ul>
    );
  }
}


MonitoringColumnList.propTypes = {
  columnData: object.isRequired,
  transportations: arrayOf(shape({
    id: string.isRequired,
  })).isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  onDetail: func.isRequired,
  subscribeToAddLiveMonitoring: func.isRequired,
  subscribeToRemoveLiveMonitoring: func.isRequired,
};


export default MonitoringColumnList;
