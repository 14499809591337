import React, { Component, } from 'react';
import { object, string, } from 'prop-types';

import { QUERY_GATEHOUSE_TRANSLATIONS, } from '../gql/translations';
import TabActual from '../components/TabActual/TabActual';
import TabInArea from '../components/TabInArea/TabInArea';
import TabHistory from '../components/TabHistory/TabHistory';
import TabNotifications from '../components/TabNotifications/TabNotifications';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import GatehouseView from '../components/GatehouseView';
import RecordCreate from '../components/RecordEdit/RecordCreate';
import TransportationCreate from '../../Transportation/components/TransportationCreate/TransportationCreate';
import { onlyUnique, } from '../../../logic/array';
import { withTranslations, } from '../../../logic/translations/withTranslations';

export const GATEHOUSE_TABS = {
  ACTUAL: 'actual',
  IN_AREA: 'in_area',
  HISTORY: 'history',
  NOTIFICATIONS: 'notifications',
};

class GatehousePage extends Component {
  state = {
    activeTab: GATEHOUSE_TABS.ACTUAL,
    createRecordModal: {
      isOpen: false,
    },
    createTransportationModal: {
      isOpen: false,
      prefilledData: undefined,
    },
  };

  handleChangePage = (page) => {
    this.setState({ activeTab: page, });
  }

  handleToggleRecordCreate = () => {
    this.setState((prevState) => ({
      createRecordModal: {
        isOpen: !prevState.createRecordModal.isOpen,
      },
    }));
  }

  handleToggleTransportationCreate = (prefilledData = undefined) => {
    const { createTransportationModal, } = this.state;
    if (!prefilledData || createTransportationModal.isOpen) {
      this.setState((prevState) => ({
        createTransportationModal: {
          isOpen: !prevState.createTransportationModal.isOpen,
          prefilledData,
        },
        createRecordModal: {
          isOpen: false,
        },
      }));
      return;
    }
    this.setState((prevState) => ({
      createTransportationModal: {
        isOpen: !prevState.createTransportationModal.isOpen,
        prefilledData: {
          ...prefilledData,
          transportations: this.getTransportations(prefilledData),
          orders: this.getOrders(prefilledData),
          gateRecord: this.getGateRecord(prefilledData),
        },
      },
      createRecordModal: {
        isOpen: false,
      },
    }));
  }

  getOrders = (prefilledData) => prefilledData.orders.map((o) => ({
    transportationTypeId: o.transportationTypeId,
    orderNo: o.no,
    actionId: o.actionId,
  }))

  getTransportations = (prefilledData) => onlyUnique(prefilledData.orders.map((o) => o.transportationTypeId))
    .map((ttId) => {
      const filteredOrders = prefilledData.orders.filter((o) => o.transportationTypeId === ttId);
      return ({
        transportationTypeId: ttId,
        transportationTypeName: filteredOrders[0].transportationTypeName,
        no: filteredOrders.map((o) => o.no).join((',')),
      });
    })

  getGateRecord = (prefilledData) => ({
    id: prefilledData.id,
    gateVehicleGroupId: prefilledData.gateVehicleGroupId.id,
    gateType: prefilledData.gateType.id,
    time: prefilledData.time,
    note: prefilledData.note,
  })

  render() {
    const { activeTab, createRecordModal, createTransportationModal, } = this.state;
    const { translations, languageId, } = this.props;

    return (
      <PageFullScreen>
        {createRecordModal.isOpen && (
          <RecordCreate
            // data
            languageId={languageId}
            translations={translations}
            modalData={createRecordModal}
            // methods
            onToggle={this.handleToggleRecordCreate}
            onCreateTransportation={this.handleToggleTransportationCreate}
          />
        )}

        {createTransportationModal.isOpen && (
          <TransportationCreate
            // data
            prefilledData={createTransportationModal.prefilledData}
            fromGatehouse
            languageId={languageId}
            translations={translations}
            modalData={createRecordModal}
            // methods
            onToggle={this.handleToggleTransportationCreate}
            onCreated={() => {}}
          />
        )}

        <GatehouseView
          activeTab={activeTab}
          translations={translations}
          // methods
          onChangePage={this.handleChangePage}
          onCreateRecord={this.handleToggleRecordCreate}
        >
          {activeTab === GATEHOUSE_TABS.ACTUAL && (
            <TabActual
              // data
              languageId={languageId}
              translations={translations}
              // methods
              onCreateTransportation={this.handleToggleTransportationCreate}
            />
          )}
          {activeTab === GATEHOUSE_TABS.IN_AREA && (
            <TabInArea
              // data
              languageId={languageId}
              translations={translations}
            />
          )}
          {activeTab === GATEHOUSE_TABS.HISTORY && (
            <TabHistory
              // data
              languageId={languageId}
              translations={translations}
            />
          )}
          {activeTab === GATEHOUSE_TABS.NOTIFICATIONS && (
            <TabNotifications
              // data
              languageId={languageId}
              translations={translations}
            />
          )}
        </GatehouseView>
      </PageFullScreen>
    );
  }
}

GatehousePage.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
};

export default withTranslations(QUERY_GATEHOUSE_TRANSLATIONS)(GatehousePage);
