import React, { Component, } from 'react';
import {
  func, shape, string, object,
} from 'prop-types';

import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initCompanyForm, } from '../forms/structure';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import CompanyUpdateView from './CompanyUpdateView';


class CompanyUpdateLogic extends Component {
  constructor(props) {
    super(props);

    const {
      id,
      name,
      contactPerson,
      email,
      phoneNumber,
      identificationNumber,
      vatNumber,
      street,
      city,
      zip,
      state,
      info,
    } = props.data.fetchOneCompany;

    this.state = {
      ...initCompanyForm,
      id,
      detailForm: {
        ...initCompanyForm.detailForm,
        values: {
          ...initCompanyForm.values,
          name,
          contactPerson,
          email,
          phoneNumber,
          identificationNumber,
          vatNumber,
          street,
          city,
          zip,
          state,
          info,
        },
      },
    };
  }


  /**
   * Form - onChange
   */
  handleValueChange = (name, value) => {
    const { detailForm, } = this.state;
    const newState = changeAndValidateInput(detailForm, name, value);

    this.setState({
      detailForm: newState,
    });
  }


  /**
   * OnUpdate
   */
  handleUpdate = (mutate) => {
    const { id, detailForm, detailForm: { values, }, } = this.state;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const variables = {
        id,
        name: values.name,
        contactPerson: values.contactPerson,
        email: values.email,
        phoneNumber: values.phoneNumber,
        identificationNumber: values.identificationNumber,
        vatNumber: values.vatNumber,
        street: values.street,
        city: values.city,
        zip: values.zip,
        state: values.state,
        info: values.info,
      };
      mutate({
        variables,
      });
    }
  }


  /**
   * onUpdate Completed
   */
  handleUpdateComplete = (responseData) => {
    const {
      translations, onToggle, onUpdated, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onUpdated) onUpdated(responseData);
  }


  /**
   * onUpdate Error
   */
  handleUpdateError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(detailForm, extensions.exception.data);

              this.setState({
                detailForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  render() {
    const { detailForm, } = this.state;
    const { resources, onToggle, translations, } = this.props;

    return (
      <CompanyUpdateView
        // data
        resources={resources}
        detailForm={detailForm}
        translations={translations}
        // methods
        onChange={this.handleValueChange}
        onToggle={onToggle}
        onUpdate={this.handleUpdate}
        onUpdateCompleted={this.handleUpdateComplete}
        onUpdateError={this.handleUpdateError}
      />
    );
  }
}


CompanyUpdateLogic.propTypes = {
  // data
  resources: object.isRequired,
  data: shape({
    fetchOneCompany: shape({
      id: string.isRequired,
      name: string.isRequired,
      contactPerson: string.isRequired,
      email: string.isRequired,
      phoneNumber: string.isRequired,
      identificationNumber: string.isRequired,
      vatNumber: string.isRequired,
      street: string.isRequired,
      city: string.isRequired,
      zip: string.isRequired,
      state: string.isRequired,
      info: string.isRequired,
    }).isRequired,
  }).isRequired,
  translations: object.isRequired,
  // method
  onToggle: func.isRequired,
  onUpdated: func,
  addNotification: func.isRequired,
};

CompanyUpdateLogic.defaultProps = {
  onUpdated: undefined,
};


export default withNotifications(CompanyUpdateLogic);
