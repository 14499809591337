import React from 'react';
import {
  oneOfType, node, object, func, arrayOf, string,
} from 'prop-types';

import { GATEHOUSE_TABS, } from '../pages/GatehousePage';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import Card from '../../../atoms/Card/Card';
import Button from '../../../atoms/Button/Button';
import TabPanel from '../../../atoms/TabPanel/TabPanel';
import Tab from '../../../atoms/TabPanel/Tab';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import PartError from '../../../components/Parts/PartError';
import HeadingWihButtons from '../../../atoms/HeadingWihButtons/HeadingWihButtons';
import TabWithCount from './TabWithCount/TabWithCount';
import {
  QUERY_GATE_ACTUAL_RECORD_COUNT,
  QUERY_GATE_IN_AREA_RECORD_COUNT,
  QUERY_GATE_NOTIFICATIONS_RECORD_COUNT,
} from '../gql/queries';
import {
  SUBSCRIPTION_ADD_RECORD_TO_GATE_ACTUAL,
  SUBSCRIPTION_ADD_RECORD_TO_GATE_IN_AREA,
  SUBSCRIPTION_ADD_RECORD_TO_GATE_NOTIFICATIONS,
  SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_ACTUAL,
  SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_IN_AREA,
  SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_NOTIFICATIONS,
} from '../gql/subscriptions';


const GatehouseView = function ({
  // data
  activeTab,
  translations,
  children,
  // methods
  onChangePage,
  onCreateRecord,
}) {
  return <>
    <Breadcrumb>
      <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
      <BreadcrumbItem>{translations.common.gatehouse}</BreadcrumbItem>
    </Breadcrumb>

    <Card>

      <HeadingWihButtons>
        <h2>{translations.common.gatehouse}</h2>
        <Button
          onClick={onCreateRecord}
          color="success"
          size="sm"
        >
          {translations.gatehouse.createRecord}
        </Button>
      </HeadingWihButtons>

      <TabPanel>
        <TabWithCount
          text={translations.gatehouse.tabActual}
          name={GATEHOUSE_TABS.ACTUAL}
          activeTab={activeTab}
          onChangePage={onChangePage}
          query={QUERY_GATE_ACTUAL_RECORD_COUNT}
          queryName="fetchGateActualRecordCount"
          subscriptions={[
            SUBSCRIPTION_ADD_RECORD_TO_GATE_ACTUAL,
            SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_ACTUAL,
          ]}
        />

        <TabWithCount
          text={translations.gatehouse.tabInArea}
          name={GATEHOUSE_TABS.IN_AREA}
          activeTab={activeTab}
          onChangePage={onChangePage}
          query={QUERY_GATE_IN_AREA_RECORD_COUNT}
          queryName="fetchGateInAreaRecordCount"
          subscriptions={[
            SUBSCRIPTION_ADD_RECORD_TO_GATE_IN_AREA,
            SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_IN_AREA,
          ]}
        />

        <Tab
          name={GATEHOUSE_TABS.HISTORY}
          activeTab={activeTab}
          onClick={onChangePage}
          style={{ width: '8rem', }}
        >
          {translations.gatehouse.tabHistory}
        </Tab>

        <TabWithCount
          text={translations.gatehouse.tabNotifications}
          name={GATEHOUSE_TABS.NOTIFICATIONS}
          activeTab={activeTab}
          onChangePage={onChangePage}
          query={QUERY_GATE_NOTIFICATIONS_RECORD_COUNT}
          queryName="fetchGateNotificationsRecordCount"
          subscriptions={[
            SUBSCRIPTION_ADD_RECORD_TO_GATE_NOTIFICATIONS,
            SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_NOTIFICATIONS,
          ]}
        />
      </TabPanel>

      <ErrorBoundary
        renderError={() => <PartError />}
      >
        {children}
      </ErrorBoundary>
    </Card>
  </>;
};


GatehouseView.propTypes = {
  // data
  activeTab: string.isRequired,
  translations: object.isRequired,
  children: oneOfType([ node, arrayOf(node), ]).isRequired,
  // methods
  onChangePage: func.isRequired,
  onCreateRecord: func.isRequired,
};


export default GatehouseView;
