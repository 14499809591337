import React from 'react';
import { func, string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_GATE_RECORD_DETAIL, } from '../../gql/queries';
import Modal from '../../../../atoms/Modal/Modal';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import RecordEditLogic from './RecordEditLogic';


const RecordEdit = ({
  // data
  recordId,
  languageId,
  translations,
  // methods
  onToggle,
  onEntranceResolution,
  onAssignedTransportationToGateRecord,
}) => (
  <Modal
    isOpen
    title={translations.gatehouse.titleRecordEditModal}
    size="XL"
    onClose={onToggle}
    disablePadding
  >
    <Query
      query={QUERY_GATE_RECORD_DETAIL}
      fetchPolicy="network-only"
      variables={{ id: recordId, languageId, }}
    >
      {({ loading, error, data, }) => {
        if (loading) {
          return (
            <div className="app--padding">
              <PartLoading />
            </div>
          );
        }
        if (error || !data.fetchOneGateRecord) {
          return (
            <div className="app--padding">
              <PartError error={error} />
            </div>
          );
        }
        return (
          <RecordEditLogic
            // data
            id={recordId}
            data={data.fetchOneGateRecord}
            isEditMode
            translations={translations}
            languageId={languageId}
            // methods
            onToggle={onToggle}
            onEntranceResolution={onEntranceResolution}
            onAssignedTransportationToGateRecord={onAssignedTransportationToGateRecord}
          />
        );
      }}
    </Query>
  </Modal>
);

RecordEdit.defaultProps = {
  onAssignedTransportationToGateRecord: () => {},
};

RecordEdit.propTypes = {
  // data
  recordId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onEntranceResolution: func.isRequired,
  onAssignedTransportationToGateRecord: func,
};


export default RecordEdit;
